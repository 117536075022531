import React from 'react';
import Footer from '../Component/Footer/Footer';
import Navbar from '../Component/Navbar/Navbar';
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
const Success = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 3000);
    }, [navigate])

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className='flex flex-grow justify-center items-center'>
                <div>
                    <h1 className='roboto text-btnBlue font-bold text-5xl p-12 flex flex-row gap-4 items-center justify-center' style={{ color: 'green' }}>
                        <FaCheckCircle style={{ color: 'green', fontSize: '200px' }} />
                        Thank you for choosing us!
                    </h1>
                </div>
            </div>
            <div className='bottom-0'>
                <Footer />
            </div>
        </div>
    )
}

export default Success 