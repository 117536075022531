import React from 'react';
import Footer from '../Component/Footer/Footer';
import Navbar from '../Component/Navbar/Navbar';

const Termsofuse = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='h-screen w-full'>
            <Navbar />
            <div className='max-sm:m-10 my-20 mx-40 max-md:m-10 max-lg:my-20 max-lg:mx-40'>
                <h1 className='flex justify-start items-start roboto text-btnBlue font-bold text-4xl mt-20'>
                    Terms of Use
                </h1>
                <h2 className='flex justify-start items-start roboto text-grey8 font-bold text-xl mb-10'>
                    Last updated on 1/05/2023
                </h2>
                <div>
                    <h2 className='manrope font-bold text-2xl pb-2 border-b-[7px] my-5 border-btnGreyBorder'>
                        Our <b className='text-disabledBlue'>Mission</b>
                    </h2>
                    <ul className='flex flex-col gap-5'>
                        <li>
                            <div className="w-full h-fit flex-col justify-start items-start gap-2 inline-flex">
                                <div className="w-80 text-lg font-semibold font-Manrope leading-normal">Unmatched service</div>
                                <div className="self-stretch text-grey5 text-base font-normal font-Manrope leading-normal">Support corporate clients and financial investors with their growth strategy and international development.</div>
                            </div>
                        </li>
                        <li>
                            <div className="w-full h-fit flex-col justify-start items-start gap-2 inline-flex">
                                <div className="w-80 text-lg font-semibold font-Manrope leading-normal">Specific</div>
                                <div className="self-stretch text-grey5 text-base font-normal font-Manrope leading-normal">Consult Us core expertise lies in the ability to support our clients in understanding, analyzing and executing commercial and investment strategies in specific markets. </div>
                            </div>
                        </li>
                        <li>
                            <div className="w-full h-fit flex-col justify-start items-start gap-2 inline-flex">
                                <div className="w-80 text-lg font-semibold font-Manrope leading-normal">Experience</div>
                                <div className="self-stretch text-grey5 text-base font-normal font-Manrope leading-normal">Experience in working with and assisting a wide range of clients from international corporations to small/medium-sized businesses, from large corporate debt providers to mid-market private equity investors.  </div>
                            </div>
                        </li>
                        <li>
                            <div className="w-full h-fit flex-col justify-start items-start gap-2 inline-flex">
                                <div className="w-80 text-lg font-semibold font-Manrope leading-normal">Technology</div>
                                <div className="self-stretch text-grey5 text-base font-normal font-Manrope leading-normal">The best combination of technology and people.</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 className='manrope font-bold text-2xl pb-2 border-b-[7px] my-5 border-btnGreyBorder'>
                        Our <b className='text-disabledBlue'>Commitment</b>
                    </h2>
                    <ul className='flex flex-col gap-5'>
                        <li>
                            <div className="w-full h-fit flex-col justify-start items-start gap-2 inline-flex">
                                <div className="w-80 text-lg font-semibold font-Manrope leading-normal">Specific</div>
                                <div className="self-stretch text-grey5 text-base font-normal font-Manrope leading-normal">Consult Us core expertise lies in the ability to support our clients in understanding, analyzing and executing commercial and investment strategies in specific markets. </div>
                            </div>
                        </li>
                        <li>
                            <div className="w-full h-fit flex-col justify-start items-start gap-2 inline-flex">
                                <div className="w-80 text-lg font-semibold font-Manrope leading-normal">Experience</div>
                                <div className="self-stretch text-grey5 text-base font-normal font-Manrope leading-normal">Experience in working with and assisting a wide range of clients from international corporations to small/medium-sized businesses, from large corporate debt providers to mid-market private equity investors.  </div>
                            </div>
                        </li>
                        <li>
                            <div className="w-full h-fit flex-col justify-start items-start gap-2 inline-flex">
                                <div className="w-80 text-lg font-semibold font-Manrope leading-normal">Technology</div>
                                <div className="self-stretch text-grey5 text-base font-normal font-Manrope leading-normal">The best combination of technology and people.</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Termsofuse