import React, { useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Swal from "sweetalert2";
import book_workshop from "../Assets/Images/book_workshop.svg";
import ClassButton from "../Component/ClassButton";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";
import { Bookworkshop, getAllcirculum } from "../Redux/ApiCalls";
import { isValidEmail } from "../utils/functions";
// import trophy from "../Assets/Images/trophy.png";
import books from "../Assets/Icons/books.svg";
import backpack from "../Assets/Icons/Backpack.svg";
import booksLkg from "../Assets/Icons/books-lkg.svg";
import bookself from "../Assets/Icons/Bookshelf.svg";
import planet from "../Assets/Icons/Planet.svg";
import book_workshop1 from "../Assets/Images/BookWorkShop1.png";
import book_workshop2 from "../Assets/Images/BookWorkShop2.png";
import book_workshop3 from "../Assets/Images/BookWorkShop3.png";
import book_workshop4 from "../Assets/Images/BookWorkShop4.png";
import book_workshop5 from "../Assets/Images/BookWorkShop5.png";
import book_workshop6 from "../Assets/Images/BookWorkShop6.png";
import book_workshop7 from "../Assets/Images/BookWorkShop7.png";
import book_workshop8 from "../Assets/Images/BookWorkShop8.png";
// import rect from '../Assets/Images/Hero.png';
// import rect2 from '../Assets/Images/Hero2.png';

import vector2 from "../Assets/Images/Vector-1.svg";
import vector1 from "../Assets/Images/Vector.svg";
// import back_title from '../Assets/Images/Vector.png';
// import Tag from '../Component/Tag';
// import feeStructure from '../Assets/Images/fees.png';
import Fees from "../Component/Fees";

// import kidHoldingTab from '../Assets/Images/kidHoldingTab.png'
import kids_peaking from "../Assets/Images/kids_peaking.png";
import { errorHandler } from "../utils/errorHandler";
import UserContext from "../utils/UserContext";

const Workshop = () => {
  const { formref } = useContext(UserContext);
  const dispatch = useDispatch();
  const targetDivRef = useRef(null);
  const { isFetching, error, errMsg } = useSelector((state) => state.workshop);
  const { gradesData } = useSelector((state) => state.grades);
  const [buttons, setButtons] = React.useState([]);
  const [isRegistered, setIsRegistered] = React.useState(false);
  const [formdata, setFormdata] = React.useState({
    name: "",
    email: "",
    mobile_no: "",
    school_name: "",
    school_district: "",
    hosted_workshop: "",
    description: "",
    workshop_parents: "",
    workshop_schedule: "",
    workshop_delivered: false,
    workshop_educator: "Karmill",
    workshop_format: "In-person",
    have_questions: true,
    school_eligible: true
  });
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 6,
        },
      },
    ],
  };
  const Tocontact = () => {
    navigate("/contactus");
    if (formref.current) {
      formref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function getRandomIndex(arr) {
    return Math.floor(Math.random() * arr.length);
  }
  const imgSrcOptions = [backpack, planet, bookself, books, booksLkg];
  const btnColors = [
    "iconYellow",
    "iconred",
    "iconPurple",
    "btnBlue",
    "iconBlue",
  ];

  const createButtons = (data) => {
    return new Promise((resolve, reject) => {
      try {
        const solvedArray = data.map((grade, index) => ({
          imgSrc: imgSrcOptions[getRandomIndex(imgSrcOptions)],
          text: grade,
          className: btnColors[getRandomIndex(btnColors)],
          index: index,
        }));
        resolve(solvedArray);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formdata.name === "" ||
      formdata.email === "" ||
      formdata.mobile_no === "" ||
      formdata.school_name === "" ||
      formdata.school_district === "" ||
      formdata.hosted_workshop === "" ||
      formdata.description === "" ||
      formdata.workshop_parents === "" ||
      formdata.workshop_schedule === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All fields should be filled",
      });
      return;
    } else if (!isValidEmail(formdata?.email)) {
      return Swal.fire({
        icon: "error",
        title: "Validation error",
        text: "Please enter proper email address!",
      });
    } else if (formdata?.phone.length < 8) {
      return Swal.fire({
        icon: "error",
        title: "Validation error",
        text: "Please fill mobile number!",
      });
    }
    await Bookworkshop(dispatch, formdata);
    setIsRegistered(true);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: `Dear ${formdata.name}, your form is registered!`,
    });
  };

  if (error && !isFetching && isRegistered) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: errorHandler(errMsg),
    });
    setIsRegistered(false);
  }

  let settingsClassButton = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 6,
        },
      },
    ],
  };

  const scrollToDiv = () => {
    // Scroll to the top of the target div
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function extractTitles(programs) {
    return new Promise((resolve, reject) => {
      try {
        if (!Array.isArray(programs)) {
          throw new Error("Input must be an array");
        }

        const titles = programs.map((program) => program.title);
        resolve(titles);
      } catch (error) {
        reject(error);
      }
    });
  }

  const handleClassButtons = (butt) => {
    setButtons(butt);
  };

  const handleFetch = async () => {
    await getAllcirculum(dispatch);

    // handleClassButtons(createButtons(extractTitles(gradesData)));
    // console.log(btns);
    // groupedClassButtons = splitIntoGroups(buttons, 5);
    // groupedClassButtonsPhone = splitIntoGroups(buttons, 3);
  };

  React.useEffect(() => {
    extractTitles(gradesData).then((titles) => {
      createButtons(titles).then((solvedArray) => {
        handleClassButtons(solvedArray);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradesData]);

  React.useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="overflow-x-clip">
      <Navbar />
      <div className="book-workshop-banner"></div>
      <div>
        <div className="flex gap-10 w-fit max-sm:mt-0 max-sm:w-full max-sm:justify-center max-sm:items-center max-sm:gap-5 but-con">
          <button
            onClick={scrollToDiv}
            className="flex items-center justify-center h-12 p-4 text-white bg-btnBlue rounded-xl max-sm:h-10 w-50 max-sm:w-40 manrope text-md max-sm:text-sm hover:scale-90 book-workshop-button-2"
          >
            Book a workshop
          </button>
        </div>
        {/* <div className="absolute flex items-center justify-center -translate-x-1/2 translate-y-1/2 top-1/2 sm:top-[70%] left-1/2">
          <button
            onClick={scrollToDiv}
            className="p-3 text-sm text-white rounded-md sm:text-sm sm:p-4 md:text-md bg-btnBlue sm:rounded-xl manrope"
          >
            Book a workshop
          </button>
        </div> */}
      </div>

      {buttons.length === 0 ? (
        <div className="w-8 h-8 bg-transparent border border-t-8 rounded-full" />
      ) : (
        <div id="grades" className="flex flex-col justify-center items-center  mt-[1vh] py-5 gap-10">
          <h1 className="text-4xl mb-3 max-sm:text-3xl  font-[700] text-btnBlue">
            Explore our classroom workshops
          </h1>
          <p className="px-4 text-2xl text-center whitespace-pre-wrap urbanist max-sm:text-base max-sm:text-center">
            Click on your grade to see what you will learn. Fun awaits you!
          </p>
          <div className="flex items-center justify-center workshop-slick">
            <Slider {...settingsClassButton}>
              {buttons?.map((buttons, index) => (
                <ClassButton data={buttons} key={index} />
              ))}
            </Slider>
          </div>
          {/* <div className="flex justify-center w-full pt-10 mt-10">
            <div className="flex items-center justify-center w-full">
              <div className="grid transition-transform duration-300 ease-in-out grid-flow-col auto-cols-max place-items-center lg:w-[1100px] md:w-[430px] max-sm:w-[360px]">
                <Carousel
                  className="flex-row items-center justify-center w-full max-sm:hidden lg:flex md:hidden"
                  autoPlay
                  stopOnHover
                  infiniteLoop
                  showThumbs={false}
                >
                  {groupedClassButtons?.map((buttons, i) => (
                    <div
                      key={i}
                      className="flex-row gap-4 sm:hidden lg:flex md:hidden"
                    >
                      {buttons.map((button, i) => (
                        <ClassButton data={button} key={i} />
                      ))}
                    </div>
                  ))}
                </Carousel>
                <Carousel
                  className="flex-row items-center justify-center w-full max-sm:flex lg:hidden md:flex"
                  autoPlay
                  infiniteLoop
                  stopOnHover
                  showThumbs={false}
                >
                  {groupedClassButtonsPhone?.map((buttons, i) => (
                    <div
                      key={i}
                      className="flex-row gap-4 max-sm:flex lg:hidden md:flex"
                    >
                      {buttons.map((button, i) => (
                        <ClassButton data={button} key={i} />
                      ))}
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div> */}
        </div>
      )}

      <div>
        <div className="flex flex-col items-center justify-start h-full">
          <div className="absolute left-0 z-0 w-20 max-sm:w-10">
            <img
              src={vector2}
              alt="vector"
              className="absolute right-0 z-999 max-sm:w-8"
            />
            <img src={vector1} alt="vector" className="max-sm:w-8" />
          </div>

          {/* <div className='flex flex-row items-center justify-center w-screen gap-12 mb-32'>
                        <button>
                            <img src={chevronL} alt="left arrow" />
                        </button>
                        <div className='flex flex-row items-center justify-around w-auto gap-5 overflow-auto'>
                            <div className="w-[336px] h-[230px] relative">
                                <div className="w-[316px] h-[215px] left-[20px] top-0 absolute z-0  bg-opacity-10 rounded-2xl border border-ringYellow" />
                                <img className=" w-[317px] h-[215px] left-0 top-[15px] absolute z-0  rounded-2xl" src={con_1} alt='con_1' />
                            </div>
                            <div className="w-[336px] h-[230px] relative">
                                <div className="w-[316px] h-[215px] left-[20px] top-0 absolute z-0  bg-opacity-10 rounded-2xl border border-ringBlue" />
                                <img className=" w-[317px] h-[215px] left-0 top-[15px] absolute z-0  rounded-2xl" src={con_2} alt='con_1' />
                            </div>
                            <div className="w-[336px] h-[230px] relative">
                                <div className="w-[316px] h-[215px] left-[20px] top-0 absolute z-0  bg-opacity-10 rounded-2xl border border-ringBlue" />
                                <img className="w-[317px] h-[215px] left-0 top-[15px] absolute z-0  rounded-2xl" src={con_3} alt='con_1' />
                            </div>
                        </div>
                        <button>
                            <img src={chevronR} alt="right arrow" />
                        </button>
                    </div> */}
          <div className="absolute z-0 max-sm:hidden md:block lg:block rotate-45 top-[130vh] left-[-2vw] border-[10px] border-backOrange h-[88px] w-[88px]" />

          <div className="relative flex flex-row items-center justify-center w-screen gap-20 py-8 mb-10 max-sm:px-5 workshop-gradient max-sm:gap-10 h-96">
            <img
              src={book_workshop1}
              data-aos="zoom-in-up"
              alt=""
              className="absolute left-[10vw] top-0 max-sm:w-20"
            />
            <img
              src={book_workshop2}
              data-aos="zoom-in-up"
              alt=""
              className="absolute left-[5vw] top-50 max-sm:top-20 max-sm:w-20"
            />
            <img
              src={book_workshop3}
              data-aos="zoom-in-up"
              alt=""
              className="absolute left-[15vw] max-sm:w-20 desk-view"
            />
            <img
              src={book_workshop4}
              data-aos="zoom-in-up"
              alt=""
              className="absolute left-[10vw] bottom-0 max-sm:w-20"
            />

            <div className="z-50 flex flex-col items-center justify-center w-1/2 gap-5 max-sm:w-full">
              <img src={book_workshop} alt="book a workshop" />
              <p className="text-lg text-center whitespace-pre-wrap urbanist text-grey9 max-sm:text-center max-sm:text-base">
                Complete the form below and we will be in touch.
              </p>
            </div>

            <img
              src={book_workshop5}
              data-aos="zoom-in-up"
              alt=""
              className="absolute right-[10vw] top-0 max-sm:w-20"
            />
            <img
              src={book_workshop6}
              data-aos="zoom-in-up"
              alt=""
              className="absolute right-[5vw] top-50 max-sm:top-20 max-sm:w-20"
            />
            <img
              src={book_workshop8}
              data-aos="zoom-in-up"
              alt=""
              className="absolute right-[15vw] max-sm:w-20 desk-view"
            />
            <img
              src={book_workshop7}
              data-aos="zoom-in-up"
              alt=""
              className="absolute right-[10vw] bottom-0 max-sm:w-20"
            />
          </div>
        </div>
      </div>

      {/* book workshop form  */}
      <div
        className="flex flex-col items-center justify-center w-full gap-10 mb-16 max-sm:gap-10"
        ref={targetDivRef}
      >
        <div className="flex flex-col items-center justify-center w-8/12 urbanist max-sm:w-full h-fit">
          <div
            className="text-white w-full h-[100px] text-xl max-sm:text-lg flex justify-center items-center text-center urbanist bg-backOrange p-4 max-sm:rounded-none rounded-xl"
          >
            "The number one problem in today's generation and economy is the lack of financial literacy." <br />
            -Alan Greenspan
          </div>
          <form
            onSubmit={handleSubmit}
            className="p-10 m-10 bg-white shadow-2xl max-sm:p-5 max-sm:m-0 rounded-xl max-sm:shadow"
          >
            <div className="flex flex-col gap-6 mt-4 mb-5">
              <div className="max-sm:w-full w-[50vw]">
                <h1
                  className="text-2xl font-semibold "
                  style={{ textTransform: "uppercase" }}
                >
                  About you
                </h1>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex flex-col w-11/12 gap-5">
                  <label htmlFor="name" className="text-base font-normal ">
                    Your name
                  </label>
                  <input
                    type="text"
                    onChange={(event) =>
                      setFormdata({ ...formdata, name: event.target.value })
                    }
                    className="p-5 border shadow_yellow focus:ring-0 border-field rounded-xl focus:outline-none lg:h-14 placeholder:manrope placeholder:text-grey10"
                    placeholder="Your full name"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="grid w-11/12 grid-cols-2 gap-2 max-sm:grid-cols-1 max-sm:gap-5">
                  <div className="flex flex-col gap-5">
                    <label htmlFor="email" className="text-base font-normal">
                      Your email
                    </label>
                    <input
                      type="text"
                      onChange={(event) =>
                        setFormdata({ ...formdata, email: event.target.value })
                      }
                      className="p-5 border shadow_yellow focus:ring-0 border-field rounded-xl focus:outline-none lg:h-14 placeholder:manrope placeholder:text-grey10"
                      placeholder="Your Email here"
                    />
                  </div>
                  <div className="flex flex-col gap-5">
                    <label htmlFor="phone" className="text-base font-normal ">
                      Phone
                    </label>
                    <input
                      type="number"
                      onChange={(event) =>
                        setFormdata({
                          ...formdata,
                          mobile_no: event.target.value,
                        })
                      }
                      className="p-5 border shadow_yellow focus:ring-0 border-field rounded-xl focus:outline-none lg:h-14 placeholder:manrope placeholder:text-grey10"
                      placeholder="Enter your contact no"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <div className="max-sm:w-full w-[50vw]">
                <h1
                  className="text-2xl font-semibold "
                  style={{ textTransform: "uppercase" }}
                >
                  About your school
                </h1>
              </div>
              <div className="flex items-center justify-center">
                <div className="grid w-11/12 grid-cols-2 gap-2 max-sm:grid-cols-1 max-sm:gap-5">
                  <div className="flex flex-col gap-5">
                    <label htmlFor="name" className="text-base font-normal">
                      School name
                    </label>
                    <input
                      type="text"
                      onChange={(event) =>
                        setFormdata({
                          ...formdata,
                          school_name: event.target.value,
                        })
                      }
                      className="p-5 border shadow_yellow focus:ring-0 border-field rounded-xl focus:outline-none lg:h-14 placeholder:manrope placeholder:text-grey10"
                      placeholder="Your school name"
                    />
                  </div>
                  <div className="flex flex-col gap-5">
                    <label htmlFor="name" className="text-base font-normal">
                      School district
                    </label>
                    <input
                      type="text"
                      onChange={(event) =>
                        setFormdata({
                          ...formdata,
                          school_district: event.target.value,
                        })
                      }
                      className="p-5 border shadow_yellow focus:ring-0 border-field rounded-xl focus:outline-none lg:h-14 placeholder:manrope placeholder:text-grey10"
                      placeholder="District Name"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="grid w-11/12 grid-cols-2 max-sm:grid-cols-1 max-sm:gap-5">
                  <div className="flex flex-col col-span-2 gap-5">
                    <h2 className="text-base manrope w-fit">
                      Has your school hosted our Financial Educational Workshops
                      before?
                    </h2>
                  </div>
                  <div className="flex flex-row items-start justify-start gap-5 my-5">
                    <div className="flex items-center justify-center gap-4">
                      <input
                        type="radio"
                        onChange={(event) =>
                          setFormdata({
                            ...formdata,
                            hosted_workshop: event.target.value,
                          })
                        }
                        name="school_hosted"
                        id="yes_radio"
                      />
                      <label htmlFor="yes_radio">Yes</label>
                    </div>
                    <div className="flex items-center justify-center gap-4">
                      <input
                        type="radio"
                        onChange={(event) =>
                          setFormdata({
                            ...formdata,
                            hosted_workshop: event.target.value,
                          })
                        }
                        name="school_hosted"
                        id="no_radio"
                      />
                      <label htmlFor="no_radio">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <div className="max-sm:w-full w-[50vw]">
                <h1
                  className="text-2xl font-semibold "
                  style={{ textTransform: "uppercase" }}
                >
                  About the workshops
                </h1>
              </div>
              {/* <div className='flex items-center justify-center'>
                                <div className='grid w-11/12 grid-cols-2 max-sm:grid-cols-1 max-sm:gap-5'>
                                    <div className='flex flex-col col-span-2 gap-5'>
                                        <h2 className='text-base manrope w-fit'>
                                            How could you like the workshops delivered ?
                                        </h2>
                                    </div>
                                    <div className='flex flex-row items-start justify-start gap-5 my-5 max-sm:flex-col'>
                                        <div className='flex items-center justify-center gap-4'>
                                            <input type="radio" name="workshop_delivered" id="in_person" />
                                            <label htmlFor="inPerson">In-person</label>
                                        </div>
                                        <div className='flex items-center justify-center gap-4'>
                                            <input type="radio" name="workshop_delivered" id="online" />
                                            <label htmlFor="online">Online</label>
                                        </div>
                                        <div className='flex items-center justify-center gap-4'>
                                            <input type="radio" name="workshop_delivered" id="either_is_fine" />
                                            <label htmlFor="either_is_fine">Either is fine</label>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              <div className="flex items-center justify-center">
                <div className="grid w-11/12 grid-cols-1 gap-5">
                  <div className="flex flex-col gap-5">
                    <h2 className="text-base text-left w-fit">
                      Which grades would you like Fiscal Kids workshops for? How
                      many classes are in each grade?
                    </h2>
                  </div>
                  <textarea
                    onChange={(event) =>
                      setFormdata({
                        ...formdata,
                        description: event.target.value,
                      })
                    }
                    type="text"
                    rows={4}
                    name="grade"
                    id="grade"
                    className="p-4 border shadow_yellow placeholder:urbanist border-field rounded-xl focus:outline-none placeholder:text-grey10"
                    placeholder="Description"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex flex-col w-11/12 gap-5">
                  <div className="flex flex-col col-span-2 gap-5">
                    <h2 className="text-base w-fit">
                      Would you like to have an introductory workshop for
                      parents?
                    </h2>
                  </div>
                  <div className="flex flex-row items-start justify-start gap-5 my-5 mt-0 max-sm:flex-col">
                    <div className="flex items-center justify-center gap-4">
                      <input
                        onChange={(event) =>
                          setFormdata({
                            ...formdata,
                            workshop_parents: event.target.value,
                          })
                        }
                        type="radio"
                        name="workshop_for_parents"
                        id="yes"
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="flex items-center justify-center gap-4">
                      <input
                        onChange={(event) =>
                          setFormdata({
                            ...formdata,
                            workshop_parents: event.target.value,
                          })
                        }
                        type="radio"
                        name="workshop_for_parents"
                        id="no"
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex flex-col w-11/12 gap-5">
                  <label
                    htmlFor="name"
                    className="text-base font-normal text-left "
                  >
                    Ideally, when would you like these workshops to take place?
                  </label>
                  <input
                    onChange={(event) =>
                      setFormdata({
                        ...formdata,
                        workshop_schedule: event.target.value,
                      })
                    }
                    type="text"
                    className="p-5 border shadow_yellow focus:ring-0 border-field rounded-xl focus:outline-none placeholder:manrope placeholder:text-grey10"
                    placeholder="Describe your schedules"
                  />
                </div>
              </div>
              {/* <div className='flex items-center justify-center'>
                                <div className='flex flex-col w-11/12 gap-5'>
                                    <label htmlFor="name" className='text-base font-normal manrope'>
                                        Do you have a preference for which educator on our team visits your school
                                    </label>
                                    <select name="preference" id="preference" className='p-5 border focus:ring-0 focus:outline-none border-field rounded-xl placeholder:poppins text-grey10'>
                                        <option value="null" selected >No Preference</option>
                                    </select>
                                </div>
                            </div> */}
              {/* <div className='flex items-center justify-center'>
                                <div className='flex flex-col w-11/12 gap-5'>
                                    <label htmlFor="name" className='text-base font-normal manrope'>
                                        Which workshop delivery format works best for your school ? 
                                        <b className='text-subHeading2'>Click here</b>  to Select options ?</label>
                                    <select name="preference" id="preference" className='p-5 border focus:ring-0 focus:outline-none border-field rounded-xl placeholder:poppins text-grey10'>
                                        <option value="null" selected>No Preference</option>
                                    </select>
                                </div>
                            </div> */}
            </div>
            <div className="flex flex-row gap-5 mt-10">
              <input
                onChange={(event) =>
                  setFormdata({
                    ...formdata,
                    have_questions: event.target.value ? true : false,
                  })
                }
                type="checkbox"
                name="general_questions"
                id="general_questions"
              />
              <label htmlFor="general_questions">
                I have some general questions about Fiscal Kids programs
                Workshops
              </label>
            </div>
            <div className="flex flex-row gap-5">
              <input
                onChange={(event) =>
                  setFormdata({
                    ...formdata,
                    school_eligible: event.target.value ? true : false,
                  })
                }
                type="checkbox"
                name="eligible"
                id="eligible"
              />
              <label htmlFor="eligible">
                I believe my school is eligible for funding from --------
              </label>
            </div>
            <div className="flex items-center justify-center w-full mt-10">
              <button
                type="submit"
                disabled={isFetching}
                className="flex flex-row items-center gap-2 px-12 py-4 text-xl font-bold text-white manrope bg-btnBlue rounded-xl disabled:bg-subHeading2 w-fit"
              >
                {!isFetching ? (
                  "Submit"
                ) : (
                  <>
                    <div className="w-8 h-8 bg-transparent border border-t-8 rounded-full animate-spin" />{" "}
                    <span>Submitting</span>
                  </>
                )}
              </button>
            </div>
            {/* <div className='absolute z-[-1] max-sm:hidden md:block lg:block w-[168px] h-[656px] bg-backOrange rounded-xl top-[300vh] left-[18vw]' /> */}
            <div className="absolute z-[-1] max-sm:hidden md:block lg:block rotate-45 top-[410vh] left-[-2vw] border-[10px] border-backOrange h-[88px] w-[88px]" />
          </form>
        </div>
        <div
          className="flex flex-row items-center justify-center pl-5 bg-backOrange w-fit max-sm:mx-2 max-sm:p-10 rounded-3xl get-in-touch"
          style={{ width: "60vw" }}
        >
          {/* <img src={kid2} alt="waving kid" className="desk-view" /> */}
          <div className="flex flex-col items-start h-30 justify-evenly ">
            <h1
              className="mb-3 font-bold text-white urbanist max-sm:text-3xl lg:text-4xl "
              style={{}}
            >
              Get in touch
            </h1>
            <p
              className="urbanist font-bold lg:text-[18px] text-grey11"
              style={{ width: "80%" }}
            >
              Keep in touch to receive the latest information
              <br /> on upcoming workshops and helpful financial literacy tips!
            </p>
            <button
              onClick={() => Tocontact()}
              className="px-5 py-4 mt-5 text-xl font-bold text-right text-white border border-white contact-us-button max-sm:w-full lg:w-3/6 bg-btnBlue rounded-xl"
            >
              Contact us!
            </button>
          </div>
          <img
            src={kids_peaking}
            alt="kids peaking"
            className="kids_peaking"
            style={{ width: "20%", position: "relative", right: "-100px" }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Workshop;
