import React from "react";
import { FaBriefcase } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { GiMatryoshkaDolls } from "react-icons/gi";
import { LiaChalkboardTeacherSolid, LiaHandsSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

import fulldaysvisit from "../Assets/Images/icons-courses/full days class room.png";
import classRoom from "../Assets/Images/icons-courses/financial class room visit.png";
import parental from "../Assets/Images/icons-courses/parenal zoom.png";
import boutique from "../Assets/Images/icons-courses/boutique.png";
import entrepreneurial from "../Assets/Images/icons-courses/entrepren.png";
import oneOneOne from "../Assets/Images/icons-courses/one to one.png";


const Fees = () => {
  const navigate = useNavigate();
  const courses = [
    {
      icon: fulldaysvisit,
      name: "Full day classroom visit",
      cost: "1495",
      rate: "Full Day (Grades 4 and up )",
      id: 1,
    },
    {
      icon: classRoom,
      name: "Financial literacy classroom visit",
      cost: "975",
      rate: "3-hour Introduction (Grades 1 to 3)",
      id: 2,
    },
    {
      icon: parental,
      name: "Parental Zoom seminar",
      cost: "375",
      rate: "",
      id: 3,
    },
    {
      icon: boutique,
      name: "Boutique group",
      cost: "195",
      rate: "Per child (Maximum 10 children)",
      id: 4,
    },
    {
      icon: entrepreneurial,
      name: "Young Entrepreneur Program (Y.E.P.)",
      cost: "750",
      rate: "",
      id: 5,
    },
    {
      icon: oneOneOne,
      name: "One-on-one coaching: empowering life skills",
      cost: "295",
      rate: "Per hour",
      id: 6,
    },
  ];

  return (
    <div type="button" className="w-screen abolute z-[999]">
      <div className="flex flex-row flex-wrap items-center justify-center w-full gap-12 px-5 max-sm:hidden">
        {courses.map((course, index) => (
          <button
            onClick={() => {
              const { icon, ...courseData } = course; // Destructuring to exclude icon
              navigate(`/payment`, { state: { data: { course, courses } } });
            }}
            type="button"
            key={index}
            className="w-[205px] zoom border-btnBlue border-b h-[290px] hover:animate-pulse shadow-lg hover:shadow-cardActive "
            style={{ borderRadius: "40px 40px 40px 40px" }}
          >
            <div
              className="flex items-center justify-center w-full bg-btnBlue "
              style={{ borderRadius: "40px 40px 0 0", height: "45%" }}
            >
              <img
                src={course.icon}
                alt=""
                width={100}
                className="aspect-square"
              />
              {/* {course.icon} */}
            </div>
            <div
              className="flex flex-col justify-between w-full p-4 text-center bg-white"
              style={{ borderRadius: "0 0 40px 40px", height: "55%" }}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                <h1 className="roboto text-md font-bolder">{course.name}</h1>
                <h1 className="text-xs inter text-grey4">{course.rate}</h1>
              </div>
              <p className="text-lg text-grey4">${course.cost}</p>
            </div>
          </button>
        ))}
      </div>
      <div className="flex-row flex-wrap items-center justify-center hidden w-full gap-4 px-5 urbanist max-sm:flex">
        {courses.map((course, index) => (
          <button
            onClick={() => {
              const { icon, ...courseData } = course; // Destructuring to exclude icon
              navigate(`/payment`, { state: { data: { course, courses } } });
            }}
            type="button"
            key={index}
            className="w-full flex h-[120px] shadow-lg hover:shadow-cardActive"
            style={{ borderRadius: "40px 40px 40px 40px" }}
          >
            <div
              className="flex items-center justify-center w-3/12 h-full bg-btnBlue"
              style={{ borderRadius: "40px  0  0 40px" }}
            >
              <img
                src={course.icon}
                alt=""
                width={50}
                className="aspect-square"
              />
            </div>
            <div
              className="flex flex-row justify-between w-full h-full p-2 bg-white"
              style={{ borderRadius: "0 40px 40px 0" }}
            >
              <div className="flex flex-col justify-center w-full gap-2 items-left">
                <h1
                  className=" text-md text-wrap font-bolder"
                  style={{ fontWeight: "500" }}
                >
                  {course.name}
                </h1>
                {/* <h5
                  className=" text-grey4"
                  style={{ fontWeight: "500", fontSize: "14px" }}
                >
                  {" "}
                  {course.rate}
                </h5> */}
              </div>
              <strong className="flex items-center justify-center w-1/3 text-lg text-grey4">
                ${course.cost}
              </strong>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Fees;
