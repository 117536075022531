import React from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import about_us_img1 from "../Assets/Images/about_us_img1.jpeg";
import about_us_img2 from "../Assets/Images/about_us_img2.jpeg";
import karmill_img from "../Assets/Images/karmell.webp";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";

const AboutUs2 = () => {
  const [isShowing, setIsShowing] = React.useState(false);
  const modal = () => {
    Swal.fire({
      title: `<strong>Karmel Leor Greenfeld</strong>`,
      html: `Karmel Leor Greenfeld stands for advocating for the empowerment of young people before graduating high school.  Her unwavering commitment to fostering financially literate, confident youth is a testament to her leadership and vision. 

      Born with an innate drive and an unwavering passion for innovation, Karmel has etched her name as a trailblazing female entrepreneur and revered business coach for over three decades. 
      
      From her formative years as a journalist with CNN in Washington, D.C., and then as a producer of financial news in New York City for Televisa/CNBC, Karmel exuded an insatiable curiosity and a thirst for knowledge. She attended McGill University and completed entrepreneurial courses at Harvard University amongst many other educational endeavors that resulted in her becoming trilingual and a global citizen.  
      
      Karmel first embarked upon her entrepreneurial journey during the dot com era at Priceline.com, driven by a desire to create and innovate. Her maiden venture in Vancouver, a boutique startup social club called Campoverde, was an embodiment of her creativity and visionary approach. It served as the catalyst that ignited her to help others create their businesses.
      
      Over the years, Karmel navigated through the dynamic landscape of business and property investment with finesse. Her ventures were not merely commercial triumphs but symbols of her commitment to fostering growth, empowering aspiring entrepreneurs, and championing women in business.
      
      Karmel then transitioned into the realm of business coaching and was shocked at how little her clients knew about how to create stable financial futures. With a wealth of experience garnered from her entrepreneurial exploits, she dedicated herself to imparting invaluable wisdom and igniting the spark of ambition in others.  Her legacy extends beyond business successes, encapsulating a legacy of empowerment and encouragement.`,
      showCloseButton: true,
    });
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="w-screen flex flex-col justify-between">
      <Navbar />
      <div className="w-screen flex flex-col items-center gap-20 md:gap-10 max-sm:gap-2">
        {/* <div
          className="w-10/12 max-sm:w-12/12 max-sm:p-2 p-10 flex-col text-white my-5
         bg-orangeBack aboutus_banner  max-sm:text-md
        max-sm:h-40 rounded-3xl roboto flex justify-center items-center"
        > */}
        <div
          className="w-10/12 max-sm:w-12/12 max-sm:p-2 flex-col text-white
         bg-orangeBack aboutus_banner  max-sm:text-md
        max-sm:h-40 roboto flex justify-center items-center"
        >
          <p
            className="urbanist md:text-xl max-sm:text-sm break-words text-center "
            style={{
              fontSize: "30px",
            }}
          >
            "Financial literacy is not an end in itself, but a step-by-step
            process. It begins in childhood and continues throughout a person's
            life to retirement.Instilling the financial literacy message in
            children are especially important because they will carry it for the
            rest of their lives." <br /> - George Karl
          </p>
        </div>
        {/* <div className="flex justify-center  items-center">
          <h1 className="text-4xl max-sm:text-3xl urbanis font-[700] text-btnBlue mb-10">
            About us
          </h1>
        </div> */}
        <div className="w-10/12 grid lg:grid-cols-12 max-sm:grid-cols-1 urbanist mission ">
          <div className="flex justify-start items-start lg:col-span-5 md:col-span-3 mission-1">
            <img
              src={about_us_img1}
              alt="kids"
              className="w-10/12 max-sm:m-4 md:m-4 shadow-lg rounded-3xl "
              style={{ width: "90%" }}
            />
          </div>
          <div className="flex justify-around items-start flex-col gap-2 lg:col-span-7 md:col-span-3 mission-2">
            <h1
              className="text-2xl max-sm:text-2xl w-full text-btnBlue urbanist font-extrabold centre"
              style={{ textTransform: "uppercase" }}
            >
              We're on a mission to bring ideas to life
            </h1>
            <p
              className=" md:text-2xl max-sm:text-xl urbanist max-sm:text-left w-10/12 max-sm:w-full"
              style={{ fontSize: "17px" }}
            >
              Fiscal Kids empowers children
              with lifelong financial skills. Our dynamic programs cultivate
              early money habits, budgeting, saving, and wise spending. Beyond
              financial stability, we emphasize emotional intelligence,
              instilling purpose and the satisfaction of achieving financial
              goals, and steering clear of debt traps. We prepare kids for a future
              of informed and responsible decision-making.
            </p>
            <main className="w-full flex max-sm:justify-center max-sm:items-center justify-start">
              <button
                onClick={() => navigate("/bookworkshop")}
                className="flex justify-center items-center bg-btnBlue text-white rounded-xl h-13  max-sm:h-10  w-65 px-6 py-3  max-sm:w-40 manrope text-xl max-sm:text-sm hover:scale-90"
              >
                Know more
              </button>
            </main>
          </div>
        </div>
        <div className="w-10/12 grid lg:grid-cols-12 max-sm:grid-cols-1 urbanist mission">
          <div className="flex justify-center items-center lg:col-span-5 md:col-span-3 order-2 flex-col">
            <img
              src={about_us_img2}
              alt="kids"
              className="w-10/12 max-sm:m-4 md:m-4 shadow-lg rounded-3xl"
              style={{ width: "90%" }}
            />
            <em className="text-black">Karmel interacting with the Grade 2 bankers and accountants.</em>
          </div>
          <div className="flex w-12/12 justify-start items-start flex-col gap-2 lg:col-span-7 md:col-span-3  mission-1">
            <h1
              className="text-2xl max-sm:text-2xl w-full text-btnBlue urbanist font-extrabold centre"
              style={{ textTransform: "uppercase" }}
            >
              Why financial literacy matters
            </h1>
            <p
              className="lg:text-xl md:text-2xl max-sm:text-xl urbanist max-sm:text-left "
              style={{ fontSize: "17px" }}
            >
              The landscape of the economy is evolving, and so should our
              approach to teaching kids about money. We recognize that
              traditional methods may not resonate with the younger generation,
              so we've developed a curriculum that incorporates games and
              real-life examples to make the learning experience not only
              educational but also a whole lot of fun!
            </p>
            <div
              className={`max-sm:${!isShowing && "hidden"
                } flex mt-5 flex-col gap-4 lg:text-xl md:text-2xl max-sm:text-xl`}
            >
              <h6 className="flex flex-row items-start gap-1 urbanist">
                <IoIosCheckmarkCircleOutline style={{ color: "green" }} />{" "}
                <p style={{ fontSize: "17px" }}>
                  At Fiscal Kids, we envision a future where financial education seamlessly integrates into their academic journey. Our aim is to see it given the same importance as traditional subjects.
                </p>
              </h6>
              <h6 className="flex flex-row items-start gap-1 urbanist">
                <IoIosCheckmarkCircleOutline style={{ color: "green" }} />{" "}
                <p style={{ fontSize: "17px" }}>
                  Having identified a gap in their education, we now provide essential money management skills combined with personal awareness and long-term goal setting.
                </p>
              </h6>
              <h6 className="flex flex-row items-start gap-1 urbanist">
                <IoIosCheckmarkCircleOutline style={{ color: "green" }} />{" "}
                <p style={{ fontSize: "17px" }}>
                  {" "}
                  Fiscal Kids covers the importance of saving, investing, smart spending and many other fundamental life skills. We empower youth through engaging workshops for confident financial stewardship.
                </p>
              </h6>
            </div>
            <main className="w-full hidden max-sm:flex max-sm:justify-center max-sm:items-center justify-start">
              <button
                onClick={() => setIsShowing(!isShowing)}
                className="flex justify-center items-center bg-btnBlue text-white rounded-xl h-13  max-sm:h-10  w-65 px-6 py-3  max-sm:w-40 manrope text-xl max-sm:text-sm hover:scale-90"
              >
                {isShowing ? "Show less" : "Show more"}
              </button>
            </main>
          </div>
        </div>

        <div
          className="relative w-screen mb-2  py-8 max-sm:px-5 flex flex-row justify-center items-center gap-20 max-sm:gap-10 h-full text-white"
          style={{ background: "rgba(184, 59, 94, 1)" }}
        >
          <div className="flex flex-col w-8/12 max-sm:w-full justify-center items-center gap-5 z-50">
            <strong className="text-4xl max-sm:text-3xl urbanist font-[700] text-white mb-4">
              Our Vision
            </strong>
            <p
              className="urbanist text-center max-sm:text-center max-sm:text-base text-lg whitespace-pre-wrap"
              style={{ fontSize: "17px" }}
            >
              Our vision extends beyond the classroom, sparking an interest in
              entrepreneurship, imparting critical thinking skills, and
              promoting confidence and empowerment in managing finances. By
              facilitating family communication on financial matters and
              preparing children to navigate the complexities of the adult
              financial world, we aim to reduce financial stress and break the
              cycle of fear around finances. <br />
              <br />
              Through our curriculum, covering essential topics such as saving,
              budgeting, investing, and credit management, we strive to empower
              children to think about money wisely, distinguishing between needs
              and wants and understanding its importance without equating it to
              happiness. We are committed to creating a generation that
              understands what it takes to make a living and then has the
              knowledge to manage their finances effectively. This will have
              knock-on effects for generations to come.
            </p>
          </div>
        </div>
        <div className="relative w-screen mb-4  py-4 max-sm:px-5 flex flex-row justify-center items-center gap-20 max-sm:gap-10 h-full text-btnBlue">
          <div className="flex flex-col w-8/12 max-sm:w-full justify-center items-center gap-5 z-50">
            <strong className="text-4xl max-sm:text-3xl urbanis font-[700] text-btnBlue mb-2">
              Meet Our Founder
            </strong>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-10 w-full mb-[100px]">
          <div className="shadow-cardActive max-sm:bg-white cardBgGR-2 rounded-[22px] max-sm:w-11/12 w-11/12 max-sm:p-4 flex gap-4 lg:flex-row max-sm:flex-col md:flex-col max-sm:items-center max-sm:justify-center founder-cont">
            <div className="w-full bg-btnBlue max-sm:bg-none rounded-[18px] p-5 img-div">
              <img
                src={karmill_img}
                className="w-full aspect-auto rounded-[18px]"
                alt="karmill"
                loading="lazy"
              />
              <h1 className="text-white max-sm:text-btnBlue md:text-sm text-center my-2 text-2xl max-sm:text-md urbanist">
                Karmel with her daughter Soleil, launching her journey as a young investor.
              </h1>
            </div>
            <div className="flex flex-col justify-around pr-10 max-sm:pr-0 max-sm:p-4">
              {/* <strong className="text-backOrange max-sm:text-black urbanish text-lg urbanist">
                Founder
              </strong> */}
              <div className="">
                <strong className="text-btnBlue text-2xl">
                  Karmel Leor Greenfeld
                </strong>
                <h2 className="text-grey4  urbanist">
                  Pioneering entrepreneur & esteemed business coach
                </h2>
              </div>
              <p
                className="text-grey4 urbanist mb-2"
                style={{ fontSize: "18px" }}
              >
                Karmel Leor Greenfeld stands for advocating for the empowerment
                of young people before graduating high school. Her unwavering
                commitment to fostering financially literate, confident youth is
                a testament to her leadership and vision.
              </p>
              <p
                className="text-grey4  urbanist mb-2"
                style={{ fontSize: "18px" }}
              >
                Born with an innate drive and an unwavering passion for
                innovation, Karmel has etched her name as a trailblazing female
                entrepreneur and revered business coach for over three decades.
              </p>
              <p
                className="text-grey4 max-sm:hidden  urbanist mb-2 para-3"
                style={{ fontSize: "18px" }}
              >
                From her formative years as a journalist with CNN in Washington,
                D.C., and then as a producer of financial news in New York City
                for Televisa/CNBC, Karmel exuded an insatiable curiosity and a
                thirst for knowledge. She attended McGill University and
                completed entrepreneurial courses at Harvard University amongst
                many other educational endeavors that resulted in her becoming
                trilingual and a global citizen.
              </p>
              <p
                className="text-grey4 max-sm:hidden md:hidden urbanist mb-2"
                style={{ fontSize: "18px" }}
              >
                Karmel first embarked upon her entrepreneurial journey during
                the dot com era at Priceline.com, driven by a desire to create
                and innovate. Her maiden venture in Vancouver, a boutique
                startup social club called Campoverde, was an embodiment of her
                creativity and visionary approach. It served as the catalyst
                that ignited her to help others create their businesses.
              </p>
              <p
                className="text-grey4 max-sm:hidden md:hidden urbanist mb-2"
                style={{ fontSize: "18px" }}
              >
                Over the years, Karmel navigated through the dynamic landscape
                of business and property investment with finesse. Her ventures
                were not merely commercial triumphs but symbols of her
                commitment to fostering growth, empowering aspiring
                entrepreneurs, and championing women in business.
              </p>

              <strong className="text-btnBlue readmore" onClick={modal}>
                Read more
              </strong>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs2;
