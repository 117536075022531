export default function FullDayVisit() {
    //course objective points
    const COURSE_OBJECTIVES = [
      "Explore self-awareness and alignment with their own dreams",
      "Understand the concepts of working, spending and saving",
      "Build confidence and self-esteem around financial topics",
      "Learn to identify whether or not they need to purchase something",
      "Understand the cost vs the price of a purchase",
      "Begin their financially awakened journey",
      "Build a foundation of timeless and lifelong skills",
      "Feel empowered to create their own success story",
      "Have the skillset to direct their own future and ask questions",
      "Begin to see marketing traps",
      "Start to imagine a career path that would be fun for them",
      "Have students begin to save and invest",
      "Begin to imagine their ideal career path",
    ];
  
    //course content points
    const COURSE_CONTENT = [
      "Envision their future selves post high school",
      "Discuss how people generate income: different salaries for different jobs",
      "Introduce the concept of active vs passive income",
      "Differentiating between needs vs wants",
      "Setting future goals",
      "Explain the importance of saving and discuss why we save",
      "Introduce the concepts of expenses, taxes and charitable giving",
      "Discuss the power of the desire to shop: instant vs delayed gratification",
    ];
  
    return (
      <div className="flex flex-col gap-6 mx-6 my-6 sm:mx-10 md:mx-14">
        {/* introduction */}
        <div className="">
          {/* heading */}
          <h3 className="text-3xl font-bold text-btnBlue">
            Introduction To Financial Literacy (Grades 4 and up)
          </h3>
          <div className="flex flex-wrap gap-5 mt-4 font-semibold text-grey2">
            <div className="flex flex-col gap-2">
              <p className="text-justify ">
                We are proud to be one of Canada’s first financial literacy
                workshops for children: making financial literacy fun!
              </p>
              <p className="text-justify ">
                Learning about money can be fun…playing financial games in the
                classroom is our recipe for confident, financially fit kids who
                are prepared with the tools and information they need to
                powerfully create their own future!
              </p>
              <p className="text-justify ">
                We begin our games by asking the students to get in touch with
                their own visions of their best futures. Building on the
                principles of how the world works outside of the classroom; and
                giving them awareness into who they are on the inside and what
                drives them, our workshops promise to give your children a new
                appreciation for aligning their careers with what matters to them,
                and then working hard for their money to save it. We explain
                intelligent spending, giving to charity and reveal the often not
                discussed concept of investing for their future. In our whole
                scope of educational enrichment, financial literacy skills are the
                one thing that we must offer our children that's not all that
                common yet is integral to their future success. How children think
                about money - with knowledge, understanding and a feeling of being
                in control, is an invaluable asset. The earlier that you start
                teaching financial literacy, the more ingrained these habits
                become in their daily lives.
              </p>
              <p className="text-justify ">
                We are providing students with the inner awareness and financial
                literacy skills necessary to secure a successful future!
              </p>
            </div>
          </div>
        </div>
        {/* course content */}
        <div className="">
          {/* heading */}
          <h3 className="text-3xl font-bold text-btnBlue">Course Content</h3>
          <ul className="flex flex-col gap-2 mt-4 ms-6 text-grey2">
            {COURSE_CONTENT.map((ct, i) => {
              return (
                <li key={i} className="list-disc">
                  {ct}
                </li>
              );
            })}
          </ul>
        </div>
  
        {/* course objectives */}
        <div className="">
          {/* heading */}
          <h3 className="text-3xl font-bold text-btnBlue">Course Objectives</h3>
          <ul className="flex flex-col gap-2 mt-4 ms-6 text-grey2">
            {COURSE_OBJECTIVES.map((ob, i) => {
              return (
                <li key={i} className="list-disc">
                  {ob}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
  