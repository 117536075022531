/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CourseCard from "../Component/CourseBuyCard";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";
const CourseDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { gradesData } = useSelector((state) => state.grades);
  // const { id } = useParams();
  const id = location.state && location.state.dataIndex;
  const [grade, setGrade] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    console.log('curr', id);
    if (id > gradesData.length) {
      navigate('/');
    }
    setIsLoading(false);
    setGrade(gradesData[id]);
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="my-10 w-9/12 urbanist cur-banner rounded-3xl">
        <p className="max-sm:p-4 p-10 max-sm:text-[17px] text-[30px]"
        >
          "We were not taught financial literacy in school. It takes a lot of work and time to
          change your thinking and to become financially literate." <br /> - Robert Kiyosaki
        </p>
      </div>
      <div className="flex justify-center items-start flex-grow ">
        {isLoading ? (
          <div className="flex gap-5 w-full py-2 px-12  justify-center manrope bg rounded-xl text-btnBlue font-bold text-xl">
            <div className="w-8 h-8 bg-transparent rounded-full animate-spin border border-t-8" />
            <span>Loading...</span>
          </div>
        ) : (
          <div className="grid grid-cols-6 my-10 w-9/12 cur-con">
            <div className="lg:col-span-4 w-full max-sm:col-span-6 md:col-span-6 max-sm:order-2 flex justify-start items-start">
              <div className="flex flex-col gap-5 w-full">
                <h1 className="roboto text-btnBlue font-bold text-3xl capitalize max-sm:text-xl">
                  {grade?.title}
                </h1>
                {grade?.description !== "N/A" && (
                  <p className="manrope font-bold text-btnBlue text-xl whitespace-pre-wrap max-sm:text-lg">
                    {grade?.description}
                  </p>
                )}

                <div>
                  <h1 className="roboto font-bold text-xl text-btnBlue max-sm:text-lg">
                    Curriculum
                  </h1>
                </div>
                <div className="w-full">
                  <div className="w-full mx-auto max-sm:px-0 mb-5 bg-white min-h-sceen">
                    <div className="grid w-full mx-auto cont-cu">
                      {isLoading ? (
                        <div className="flex gap-5 w-full py-4 px-12  manrope bg rounded-xl text-btnBlue font-bold text-xl">
                          <div className="w-8 h-8 bg-transparent rounded-full animate-spin border border-t-8" />
                          <span>Loading...</span>
                        </div>
                      ) : (
                        grade?.lectures?.map((sect, index) => (
                          <div
                            className="py-5 px-4 max-sm:px-0 m-2 roboto border border-field container-cur"
                            key={index}
                          >
                            <details className="group">
                              <summary className="flex justify-start items-center font-medium cursor-pointer list-none">
                                <div className="flex">
                                  <span className="transition group-open:rotate-180">
                                    <svg
                                      fill="none"
                                      height="24"
                                      shapeRendering="geometricPrecision"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                      viewBox="0 0 24 24"
                                      width="24"
                                    >
                                      <path d="M6 9l6 6 6-6"></path>
                                    </svg>
                                  </span>
                                  <span className="roboto font-bold title-cur">
                                    {" "}
                                    {sect.section.title}
                                  </span>
                                </div>
                              </summary>
                              <div className="text-neutral-600group-open:animate-fadeIn">
                                <div
                                  className="flex gap-2 flex-col"
                                  style={{ marginLeft: "18px" }}
                                >
                                  {sect.section.lessons.map((lesson, index) => (
                                    <div
                                      key={index}
                                      className="flex p-2 gap-2 justify-start items-start flex-col w-full roboto font-bold"
                                    >
                                      <div className=" flex gap-2 text-left text-btnBlue whitespace-pre-wrap">
                                        {lesson.lesson_desc}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </details>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-2 max-sm:col-span-6 md:col-span-6 flex justify-center items-start w-full md:order-2 max-sm:order-2">
              <CourseCard title={grade?.title} desc={grade?.description} index={id}/>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CourseDetail;
