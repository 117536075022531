import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./Slices/AuthSlice";
import ResetPasswordReducer from "./Slices/ResetPasswordSlice";
import CourseReducer from "./Slices/CourseSlice";
import TestimonialReducer from "./Slices/TestimonialSlice";
import StaticReducer from "./Slices/StaticSlice";
import CartReducer from './Slices/CartSlice';
import WorshopReducer from './Slices/WorkshopSlice';
import ContactReducer from "./Slices/ContactSlice";
import GradesReducer from "./Slices/GradeSlice";

export default configureStore({
    reducer: {
        auth: authReducer,
        resetPassword: ResetPasswordReducer,
        courses: CourseReducer,
        testimonials: TestimonialReducer,
        static: StaticReducer,
        cart: CartReducer,
        workshop: WorshopReducer,
        contact: ContactReducer,
        grades: GradesReducer
    }
})