import { createSlice } from "@reduxjs/toolkit";

const courseSlice = createSlice({
    name: 'courseSlice',
    initialState: {
        isFetching: false,
        error: false,
        errMsg: "",
        categories: [],
        courses: [],
        courseDetails: {},
    },
    reducers: {
        getAllStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        getAllSuccess: (state, action) => {
            const { message } = action.payload;
            state.isFetching = false;
            state.error = false;
            state.errMsg = message;
            state.courses = action.payload.courses;
        },
        getAllFailure: (state, action) => {
            const { message } = action.payload;
            state.isFetching = false;
            state.error = true;
            state.errMsg = message;
        },
        getCategoriesStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        getCategoriesSuccess: (state, action) => {
            const { message } = action.payload;
            state.isFetching = false;
            state.error = false;
            state.errMsg = message;
            state.categories = action.payload.categories;
        },
        getCategoriesFailure: (state, action) => {
            const { message } = action.payload;
            state.isFetching = false;
            state.error = true;
            state.errMsg = message;
        },
        getCourseStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        getCourseSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.courseDetails = action.payload.course;
        },
        getCourseFailure: (state, action) => {
            const { message } = action.payload;
            state.isFetching = false;
            state.error = true;
            state.errMsg = message;
        },
    }
});
export const {
    getAllStart,
    getAllSuccess,
    getAllFailure,
    getCategoriesStart,
    getCategoriesSuccess,
    getCategoriesFailure,
    getCourseStart,
    getCourseSuccess,
    getCourseFailure
} = courseSlice.actions;
export default courseSlice.reducer;