import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../utils/UserContext";
// import glass from '../../Assets/Icons/glass.svg';
// import cartIcon from '../../Assets/Icons/cart.svg';
// import Logo from '../../Assets/Icons/Be.png';
import Logo from "../../Component/Logo";
// import profile_img from '../../Assets/Images/profile_img.png';
import { AiOutlineHome } from "react-icons/ai";
// import { AiOutlineShoppingCart } from 'react-icons/ai';
import { BiNetworkChart } from "react-icons/bi";
import { FaBookOpen } from "react-icons/fa";
// import { RiPagesLine } from 'react-icons/ri';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { MdClose, MdMenu } from "react-icons/md";
import { SiAboutdotme } from "react-icons/si";
// import { logOut } from '../../Redux/Slices/AuthSlice';
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosContact } from "react-icons/io";
// import { useSelector } from 'react-redux';

const Navbar = ({ to, scrollToDiv }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  const [navLoc, setNavLoc] = React.useState("Home");
  // const { cart } = useSelector((state) => state.cart);
  const { isLogged } = useContext(UserContext);
  const [user, setUser] = React.useState({});
  const [isProfileMenuOpen, setisProfileMenuOpen] = React.useState(false);
  const [isNavmenuOpen, setIsNavmenuOpen] = React.useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  function extractAndCapitalize(urlPath) {
    urlPath = urlPath.startsWith("/") ? urlPath.slice(1) : urlPath;
    const segments = urlPath.split("/");
    const lastSegment = segments[segments.length - 1];
    const capitalizedSegment =
      lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1);
    return capitalizedSegment;
  }

  // const handleLogout = async () => {
  //     await dispatch(logOut());
  //     navigate("/register/");
  // };
  React.useEffect(() => {
    // setUser(JSON.parse(localStorage.getItem("user")));
    if (currentUrl === "/") {
      setNavLoc("Home");
    } else {
      setNavLoc(extractAndCapitalize(currentUrl));
    }
  }, [currentUrl]);

  return (
    <div>
      <div className="flex-col items-center justify-between hidden w-full h-24 px-10 lg:flex bg-btnBlue text-grey">
        <div className="flex flex-row items-center justify-between w-full h-full urbanist">
          <div className="flex items-center text-2xl font-normal md:text-3xl">
            <div className="mali">
              <Logo />
            </div>
          </div>

          <div
            className="flex urbanist"
            style={{
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "700",
              gap: "60px",
            }}
          >
            <ul className="flex items-center" style={{ gap: "50px" }}>
              <Link to={"/"}>
                <li className="zoom" style={currentUrl === '/' ? { borderBottom: '2px yellow solid' } : {}}>Home</li>
              </Link>
              <Link to={"/bookworkshop"}>
                <li className="zoom" style={currentUrl === '/bookworkshop' ? { borderBottom: '2px yellow solid' } : {}}>Book Workshop</li>
              </Link>{" "}
              <Link to={"/testimonials"}>
                <li className="zoom" style={currentUrl === '/testimonials' ? { borderBottom: '2px yellow solid' } : {}}>Testimonials</li>
              </Link>
              {/* {to ? (
                <li className="cursor-pointer" onClick={to}>
                  Testimonials
                </li>
              ) : (
                <li
                  className="cursor-pointer"
                  onClick={() => navigate("/testimonials")}
                >
                  Testimonials
                </li>
              )} */}
              <div onClick={scrollToDiv ? scrollToDiv : () => navigate("/contactus")} >
                <li className="zoom" style={currentUrl === '/contactus' ? { borderBottom: '2px yellow solid' } : {}}>Contact Us</li>
              </div>
              <Link to={"/aboutus"}>
                <li className="zoom" style={currentUrl === '/aboutus' ? { borderBottom: '2px yellow solid' } : {}}>About Us</li>
              </Link>
              {/* <Link to={'/courses'}>
                                {<li>Courses</li>}
                            </Link> */}
            </ul>
            {/* <button
                            onClick={() => navigate('/courses')}
                            className='flex flex-row items-center w-full md:w-[365px] h-14 md:h-[45px] gap-4 bg-glass px-4 md:px-12 rounded-lg'>
                            <img src={glass} alt="search" className='w-6 h-6 md:w-8 md:h-8' />
                            <input type="text" name="search" id="search" className='w-full text-white bg-transparent focus:outline-none placeholder-grey placeholder-opacity-60' placeholder='Search any Workshop...' />
                        </button> */}
            {/* <button onClick={() => navigate('/cart')} className='relative flex justify-end'>
                            <span class="absolute inline-flex z-50 justify-center h-5 w-5 items-center rounded-full bg-textRed">
                                {cart.length}
                            </span>
                            <img src={cartIcon} alt="cart" className='relative inline-flex' />
                        </button> */}
            {/* <div
              onClick={() => {
                setIsNavmenuOpen(false);
                setisProfileMenuOpen(!isProfileMenuOpen);
              }}
              className="flex flex-row items-center gap-3 p-1 border rounded-full border-grey"
            > */}
            {/* <img
                src={ProfileImg}
                alt="profile_img"
                className="w-[50px] h-[40px] rounded-full workshop-gradient"
              /> */}
            {/* <p>{isLogged ? user?.firstname : "Sign in"}</p> */}
            {/* <button
                            onClick={isLogged ? () => { setIsNavmenuOpen(false); setisProfileMenuOpen(!isProfileMenuOpen) } : () => navigate('/register/')}
                            >
                                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.528758 0.528575C0.789108 0.268226 1.21122 0.268226 1.47157 0.528575L5.00016 4.05717L8.52876 0.528575C8.78911 0.268226 9.21122 0.268226 9.47157 0.528575C9.73192 0.788925 9.73192 1.21103 9.47157 1.47138L5.47157 5.47138C5.21122 5.73173 4.78911 5.73173 4.52876 5.47138L0.528758 1.47138C0.268409 1.21103 0.268409 0.788925 0.528758 0.528575Z" fill="#F2F2F2" />
                                </svg>
                            </button> */}
            {/* <div className="absolute z-50 w-48 overflow-hidden bg-white rounded-md shadow drop-down top-12 right-3">
                                <ul className={isProfileMenuOpen ? "" : "hidden"}>
                                    <Link to={'/profile'}>
                                        <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium text-grey9 hover:bg-slate-400">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.940 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.940 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.940-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.940-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </span>
                                            <span> Profile </span>
                                        </li>
                                    </Link>
                                    <button onClick={() => navigate('/mycourses')}>
                                        <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium text-grey9 hover-bg-slate-400">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                </svg>
                                            </span>
                                            <span> My Courses </span>
                                        </li>
                                    </button>
                                      Logout button 
                                    <button onClick={handleLogout}>
                                        <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium text-grey9 hover-bg-slate-400">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                                </svg>
                                            </span>
                                            <span> Logout </span>
                                        </li>
                                    </button>
                                </ul>
                            </div> */}
            {/* </div> */}
            <div>
              {/* <button onClick={() => { setIsNotificationOpen(!isNotificationOpen) }}>
                                <img src={bell} alt="bell" />
                            </button> */}
              <div className="absolute z-50 w-48 overflow-hidden bg-white rounded-md shadow-2xl drop-down text-grey9 top-12 right-3">
                <ol className={isNotificationOpen ? "" : "hidden"}>
                  {isLogged ? (
                    user?.notifications?.length === 0 ? (
                      <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover-bg-slate-400">
                        <span> No Notification </span>
                      </li>
                    ) : (
                      user?.notifications?.map((item, index) => (
                        <li
                          key={index}
                          className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover-bg-slate-400"
                        >
                          <span> {item.title} </span>
                        </li>
                      ))
                    )
                  ) : (
                    ""
                  )}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="relative flex items-center justify-between py-2 pl-0 pr-2 lg:hidden dark:bg-slate-900 bg-btnBlue">
        <div className="flex items-center justify-between w-full gap-3">
          {/* <button onClick={() => navigate('/courses')} className='flex flex-row items-center h-[45px] gap-4 bg-glass px-[6px] rounded-lg'>
                        <img src={glass} alt="search" className='w-[20px] h-[20px]' />
                        <input type="text" name="search" id="searchOne" className='bg-transparent active:text-white focus:text-white active:bg-transparent focus:bg-transparent focus:outline-none placeholder:text-grey placeholder:bg-none' placeholder='Search' />
                    </button> */}

          <div
          // onClick={() => { setIsNavmenuOpen(false); setisProfileMenuOpen(!isProfileMenuOpen) }}
          >
            <Logo />
            {/* <img src={ProfileImg} alt="profile_img" className='w-[60px] h-[40px] rounded-full workshop-gradient' /> */}
            <div className="absolute z-50 w-48 overflow-hidden bg-white rounded-md shadow drop-down top-15 left-3">
              {/* <ul className={isProfileMenuOpen ? "" : "hidden"}>
                                <Link to="/profile">

                                    <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover:bg-slate-400">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                        </span>
                                        <span> Setting </span>
                                    </li>
                                </Link>
                                <button onClick={() => navigate('/mycourses')}>
                                    <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover:bg-slate-400">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                            </svg>
                                        </span>
                                        <span> My Courses </span>
                                    </li>
                                </button>

                                <button onClick={handleLogout}>
                                    <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover:bg-slate-400">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                            </svg>
                                        </span>
                                        <span> Logout </span>
                                    </li>
                                </button>
                            </ul> */}
            </div>
          </div>
          <div className="flex flex-row items-center justify-end">
            <div className="cursor-pointer" id="mobile-toggle">
              <div
                onClick={() => {
                  setisProfileMenuOpen(false);
                  setIsNavmenuOpen(!isNavmenuOpen);
                }}
                className="flex flex-row items-center justify-center gap-2"
              >
                <button
                  className="text-xl font-extrabold text-white urbanist"
                  onClick={() => {
                    setisProfileMenuOpen(false);
                    setIsNavmenuOpen(!isNavmenuOpen);
                  }}
                >
                  {navLoc}
                </button>
                {!isNavmenuOpen ? (
                  <MdMenu className="text-2xl text-white" />
                ) : (
                  <MdClose className="text-2xl text-white" />
                )}
              </div>
              <div
                className={`drop-down ${isNavmenuOpen ? "" : "hidden"
                  } w-48 overflow-hidden bg-white rounded-md shadow absolute z-50 top-16 right-3`}
              >
                <ul>
                  <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover:bg-slate-400">
                    <span>
                      <AiOutlineHome />
                    </span>
                    <span>
                      <Link to={"/"}>Home</Link>
                    </span>
                  </li>
                  <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover:bg-slate-400">
                    <span>
                      <SiAboutdotme />
                    </span>
                    <Link to={"/aboutus"}>
                      About Us
                    </Link>
                  </li>

                  <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover:bg-slate-400">
                    <span>
                      <IoIosContact />
                    </span>
                    <span>
                      <Link to={'/contactus'}>
                        Contact us
                      </Link>
                    </span>
                  </li>
                  <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover:bg-slate-400">
                    <span>
                      <FaBookOpen />
                    </span>
                    <span>
                      <Link to={"/bookworkshop"}>Book Workshop</Link>
                    </span>
                  </li>
                  <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover:bg-slate-400">
                    <span>
                      <BiNetworkChart />
                    </span>
                    <span>
                      <Link to={"/testimonials"}>Testimonials</Link>
                    </span>
                  </li>

                  {/* <li className="flex items-center px-3 py-3 space-x-2 text-sm font-medium hover:bg-slate-400">
                                    <span>
                                        <RiPagesLine />
                                    </span>
                                    <span>
                                        <Link to={'/courses'}>
                                            Courses
                                        </Link>
                                    </span>
                                </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
