import React from "react";
import Swal from "sweetalert2";

const ParentsReviewCard = ({ data }) => {
  const modal = () => {
    Swal.fire({
      title: `<strong>${data.title}</strong>`,
      html: `${data.description}</br> </br> </br> ${data.user.name}`,
      showCloseButton: true,
    });
  };
  return (
    <div
      className="urbanist zoom flex flex-col bg-white hover:shadow-cardActive w-max  rounded-xl h-[470px] parent-review"
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      }}
    >
      <img
        src={data.user.image}
        alt="science for kids"
        // style={{ width: "290px" }}
        className=" rounded-t-xl object-top object-cover w-[320px] h-[200px]"
        loading="eager"
      />
      <div
        className="testi-box"
        style={{ width: "290px", padding: "0px 20px" }}
      >
        <h4
          className="urbanis capitalize text-[22px] max-sm:text-[20px] text-btnBlue font-[600] overflow-hidden overflow-ellipsis whitespace-nowrap mb-2"
          style={{ marginTop: "10px", fontWeight: "900" }}
        >
          {data.title}
        </h4>
        <div
          className={`h-[120px] text-grey4 inter text-ellipsis overflow-hidden`}
          // style={{ marginBottom: "20px" }}
        >
          <p className="whitespace-normal urbanist">{data.description}</p>
        </div>
        {data.description.length > 300 ? (
          <h6
            onClick={modal}
            className="text-[#B83B5E] text-md"
            style={{ fontWeight: "700", fontSize: "14px", cursor: "pointer" }}
          >
            Read more...
          </h6>
        ) : (
          <h6
            onClick={modal}
            className="text-backOrange text-md"
            style={{
              fontWeight: "700",
              visibility: "hidden",
              fontSize: "14px",
            }}
          >
            Read more...
          </h6>
        )}
        {/* <div className="" style={{ justifySelf: "flex-end" }}> */}
        {/* <div
          className="flex gap-2 inter text-btnBlue text-[14px]  w-max py-2"
        //   style={{ position: "absolute", bottom: "0px" }}
        > */}
        <h6
          style={{
            justifySelf: "flex-end",
            fontSize: "13px",
            fontWeight: "500",
            marginTop: "30px",
          }}
        >
          -{data.user.name}
        </h6>
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default ParentsReviewCard;
