import React from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/Icons/logo.webp";

const Logo = () => {
  return  <Link to={"/"}>
      <img src={logo} alt="Fiscal Kids" className="logo-img mix-blend-normal" loading="eager"/>
        </Link>
};

export default Logo;
