import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("userToken");
const cart = JSON.parse(localStorage.getItem("cart")) === null ? [] : JSON.parse(localStorage.getItem("cart"));

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cart: cart,
        token: token,
        isFetching: false,
        error: false,
        totalCost: 0,
        coursesInCart: [],
        errMsg: "",
    },
    reducers: {
        addCourse: (state, action) => {
            console.log(state.cart)
            state.cart = [...state.cart, action.payload];
            // state.cart = JSON.stringify(action.payload.user);
            localStorage.setItem("cart", JSON.stringify(state.cart));
        },
        removeCourse: (state, action) => {
            state.cart.splice(action.payload, 1);

            localStorage.setItem("cart", JSON.stringify(state.cart));
        },
        calcPrice: (state, action) => {
            // state.totalCost = [...state.totalCost, action.payload];
            state.totalCost = state.totalCost + action.payload;
        },
        addCourseCart: (state, action) => {
            state.coursesInCart = [...state.coursesInCart, action.payload];
        },
        eraseCart: (state) => {
            state.totalCost = 0;
            state.coursesInCart = [];
        },
        UpdateSuccess: (state, action) => {
            state.errMsg = "";
            state.error = false;
            state.isFetching = false;
            state.user = JSON.stringify(action.payload.user);
            localStorage.setItem("user", state.user);
        },
        UpdateFailure: (state, action) => {
            state.errMsg = action.payload;
            state.isFetching = false;
            state.error = true;
        },

        registerStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        registerSuccess: (state, action) => {
            state.error = false;
            state.errMsg = "";
            state.isFetching = false;
            state.error = false;
            state.user = JSON.stringify(action.payload.user);
            state.token = action.payload.token;

            localStorage.setItem("userToken", state.token);
            localStorage.setItem("user", state.user);
        },
        registerFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errMsg = action.payload;
        },

        logOut: (state, action) => {
            state.user = null;
            state.token = null;
            localStorage.removeItem("user");
            localStorage.removeItem("userToken");
        },
    },
});

export const {
    addCourse,
    removeCourse,
    calcPrice,
    addCourseCart,
    eraseCart
} = cartSlice.actions;
export default cartSlice.reducer;
