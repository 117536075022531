import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import call from "../Assets/Icons/call.svg";
import sms from "../Assets/Icons/sms.svg";
import contactusimg from "../Assets/Images/contact.png";
import { sendQuery } from "../Redux/ApiCalls";
import UserContext from "../utils/UserContext";
// import { errorHandler } from "../utils/errorHandler";
import { useNavigate } from "react-router-dom";
import { isValidEmail } from "../utils/functions";

// import { AiOutlineTwitter, AiFillFacebook, AiOutlineInstagram, AiFillLinkedin } from 'react-icons/ai'

const ContactUs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFetching, error } = useSelector((state) => state.contact);
  const { formref } = useContext(UserContext);
  const [sentQuery, setSentQuery] = React.useState(false);
  const [formdata, setFromdata] = React.useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const sendQueryReq = async (e) => {
    if (!formdata.name || !formdata.phone || !formdata.email || !formdata.message) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "All fields should be filled",
      });
      return;
    } else if (!isValidEmail(formdata?.email)) {
      return Swal.fire({
        icon: "error",
        title: "Validation error",
        text: "Please enter proper email address!",
      });
    } else if (formdata?.phone.length < 8) {
      return Swal.fire({
        icon: "error",
        title: "Validation error",
        text: "Please fill mobile number!",
      });
    }
    e.preventDefault();
    await sendQuery(dispatch, formdata);
    setSentQuery(true);
    Swal.fire({
      icon: "success",
      title: "Success",
      text: `Dear ${formdata.name}, your query is noted!`,
    });
    navigate("/");
  };

  if (error && !isFetching && sentQuery) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Error Occured",
    });
    setFromdata(false);
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col  justify-center items-center contact-us">
      {/* <Navbar /> */}
      <h1 className="flex justify-start items-start text-center roboto text-btnBlue mx-5 font-extrabold max-sm:text-4xl text-4xl my-10">
        Get answers to all your questions.
      </h1>
      <div className="grid grid-cols-2 max-sm:grid-cols-1 w-10/12">
        <div className="flex justify-around items-center flex-col">
          <img src={contactusimg} alt="contact us" />
          <div className="grid grid-cols-2 max-sm:grid-cols-1 max-sm:gap-5 w-fit gap-10 grid-cont">
            <a href="mailto:book@fiscal-kids.com" className="flex flex-row w-full gap-2">
              <img src={sms} alt="sms contact number" />
              <div>
                <h1 className="font-bold">Email Contact</h1>
                <p className="text-md text-grey4">book@fiscal-kids.com</p>
              </div>
            </a>
            <a href="tel:+16043554949" className="flex flex-row w-full gap-2">
              <img src={call} alt="sms contact number" />
              <div>
                <h1 className="font-bold">Telephone</h1>
                <p className="text-md text-grey4"> +1.604.355.4949</p>
              </div>
            </a>
          </div>
        </div>
        <div className="flex justify-center items-center flex-col gap-8 py-2 flex-grow">
          <form className="w-10/12 max-sm:w-full flex flex-col gap-5">
            <div className="col flex flex-row max-sm:flex-col gap-4">
              <div className="flex flex-col w-full">
                <label
                  htmlFor="name"
                  className="max-sm:large-text text-base font-bold text-subHeading mb-3"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required={true}
                  value={formdata?.name}
                  onChange={(e) =>
                    setFromdata({ ...formdata, name: e.target.value })
                  }
                  className="shadow_yellow border text-left px-5 border-field h-14 md:h-14 lg:h-14 rounded-xl"
                />
              </div>
              <div className="flex flex-col w-full">
                <label
                  htmlFor="phone"
                  className="max-sm:large-text text-base font-bold text-subHeading mb-3"
                >
                  Phone number
                </label>
                <input
                  type="number"
                  maxLength={12}
                  name="phone"
                  id="phone"
                  required={true}
                  value={formdata?.phone}
                  onChange={(e) =>
                    setFromdata({ ...formdata, phone: e.target.value })
                  }
                  className="shadow_yellow border text-left px-5 border-field h-14 md:h-14 lg:h-14 rounded-xl"
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="email"
                className="max-sm:large-text text-base font-bold text-subHeading mb-3"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                required={true}
                value={formdata?.email}
                onChange={(e) =>
                  setFromdata({ ...formdata, email: e.target.value })
                }
                className="shadow_yellow border text-left px-5 border-field h-14 md:h-14 lg:h-14 rounded-xl"
              />
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="description"
                className="max-sm:large-text text-base font-bold text-subHeading mb-3"
              >
                Description
              </label>
              <textarea
                rows={5}
                cols={5}
                name="description"
                id="description"
                required={true}
                value={formdata?.message}
                onChange={(e) =>
                  setFromdata({ ...formdata, message: e.target.value })
                }
                className="shadow_yellow border text-left px-5 border-field rounded-xl"
              />
            </div>
          </form>
          <div ref={formref}>
            <button
              onClick={sendQueryReq}
              disabled={isFetching}
              className="uppercase flex justify-center items-center gap-4 disabled:bg-subHeading roboto bg-btnBlue text-white px-20 py-4 rounded-md"
            >
              {isFetching && <div className='w-8 h-8 bg-transparent rounded-full animate-spin border border-t-8' />}
              {isFetching ? "Submitting" : "Submit"}
            </button>
          </div>

        </div>
      </div>

      <div className="" style={{ marginBottom: "100px" }}>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default ContactUs;
