/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCourse } from '../Redux/ApiCalls';

import Footer from '../Component/Footer/Footer';
import Navbar from '../Component/Navbar/Navbar';
import CourseCard from '../Component/CourseBuyCard';

import exclamation from '../Assets/Icons/exclamation.svg';

import video_icon from '../Assets/Icons/video_icon.svg';
import reward from '../Assets/Icons/reward.svg';
import download from '../Assets/Icons/download.svg';
import devices from '../Assets/Icons/devices.svg';
const CourseDetail = () => {
    const { courseDetails } = useSelector((state) => state.courses);
    const { id } = useParams();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = React.useState(true);

    const handleFetchRequest = React.useCallback(async () => {
        setIsLoading(true);
        try {
            await getCourse(dispatch, { id });
        } catch (error) {
            // Handle the error
        } finally {
            setIsLoading(false);
            console.log("Course details", courseDetails);
        }
    }, [id, dispatch]);

    useEffect(() => {
        handleFetchRequest();
    }, [handleFetchRequest])

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className='flex justify-center items-center flex-grow'>
                {isLoading ?
                    <div className='flex gap-5 w-full py-4 px-12  justify-center manrope bg rounded-xl text-btnBlue font-bold text-xl'>
                        <div className='w-8 h-8 bg-transparent rounded-full animate-spin border border-t-8' />
                        <span>
                            Loading...
                        </span>
                    </div>
                    : <div className='grid grid-cols-6 my-10 w-5/6'>
                        <div className='col-span-4 w-full max-sm:col-span-6 max-sm:order-2 flex justify-start items-start'>
                            <div className='flex flex-col gap-5 w-full'>
                                <h1 className='roboto text-btnBlue font-bold text-3xl'>
                                    {courseDetails?.title}
                                </h1>
                                <p className='manrope font-bold text-xl'>
                                    {courseDetails?.description}
                                </p>
                                <div className='roboto font-bold text-sm flex gap-4'>

                                    <p>
                                        {courseDetails?.enrolledStudent?.length} students
                                    </p>
                                </div>
                                <div className='roboto font-bold text-sm'>
                                    Created by <b className='text-backOrange2'> {courseDetails?.createdBy?.name} </b>
                                </div>
                                <div className='flex gap-4 roboto text-sm font-bold flex-row flex-wrap'>
                                    <p className='flex'>
                                        <img src={exclamation} alt="information" className='mr-2' />
                                        Last updated {new Date(courseDetails?.updatedAt).toLocaleDateString()}
                                    </p>
                                </div>
                                <div>
                                    <h1 className='roboto font-bold text-2xl text-btnBlue mb-2'>
                                        This course includes:
                                    </h1>
                                    <div className='roboto font-bold text-base grid grid-cols-2 max-sm:grid-cols-1 gap-2'>
                                        <p className='flex'>
                                            <img src={video_icon} alt="video included in this course" className='mr-2' />
                                            {Math.floor(courseDetails?.course_length / 3600)}, {Math.floor((courseDetails?.course_length % 3600) / 60)} hours on-demand video
                                        </p>
                                        <p className='flex'>
                                            <img src={devices} alt="video included in this course" className='mr-2' />
                                            Access on mobile and TV
                                        </p>
                                        <p className='flex'>
                                            <img src={download} alt="video included in this course" className='mr-2' />
                                            2 downloadable resources
                                        </p>
                                        <p className='flex'>
                                            <img src={reward} alt="video included in this course" className='mr-2' />
                                            Certificate of completion
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <h1 className='roboto font-bold text-2xl text-btnBlue mb-2'>
                                        Course content
                                    </h1>
                                    <div className='flex justify-between'>
                                        <div>
                                            <ul className='flex roboto font-bold w-max gap-5 max-sm:flex-col items-center'>
                                                <li className='w-max'>
                                                    {courseDetails?.lectures?.length} sections
                                                </li>
                                                <svg className='desk-view' width="5" height="4" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.929688 2.02344V1.76367C0.929688 1.30794 1.07552 0.936523 1.36719 0.649414C1.65885 0.357747 2.05078 0.211914 2.54297 0.211914C3.05339 0.211914 3.45215 0.357747 3.73926 0.649414C4.03092 0.936523 4.17676 1.30794 4.17676 1.76367V2.02344C4.17676 2.47461 4.0332 2.84375 3.74609 3.13086C3.45898 3.41797 3.0625 3.56152 2.55664 3.56152C2.0599 3.56152 1.66341 3.41797 1.36719 3.13086C1.07552 2.84375 0.929688 2.47461 0.929688 2.02344Z" fill="#2D2F31" />
                                                </svg>

                                                <li className='w-max'>
                                                    {courseDetails?.lectures?.length} lectures
                                                </li>
                                                <svg className='desk-view' width="5" height="4" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.929688 2.02344V1.76367C0.929688 1.30794 1.07552 0.936523 1.36719 0.649414C1.65885 0.357747 2.05078 0.211914 2.54297 0.211914C3.05339 0.211914 3.45215 0.357747 3.73926 0.649414C4.03092 0.936523 4.17676 1.30794 4.17676 1.76367V2.02344C4.17676 2.47461 4.0332 2.84375 3.74609 3.13086C3.45898 3.41797 3.0625 3.56152 2.55664 3.56152C2.0599 3.56152 1.66341 3.41797 1.36719 3.13086C1.07552 2.84375 0.929688 2.47461 0.929688 2.02344Z" fill="#2D2F31" />
                                                </svg>

                                                <li className='w-max'>
                                                    {Math.round(courseDetails?.course_length / 60)} hours {Math.round(courseDetails?.course_length % 60)}min total length
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='font-bold text-ringYellow'>
                                            Expand all sections
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <div className="w-full mx-auto max-sm:px-0 mb-5 bg-white min-h-sceen">
                                        <div className="grid w-full mx-auto mt-8">
                                            {isLoading ? <div className='flex gap-5 w-full py-4 px-12  manrope bg rounded-xl text-btnBlue font-bold text-xl'>
                                                <div className='w-8 h-8 bg-transparent rounded-full animate-spin border border-t-8' />
                                                <span>
                                                    Loading...
                                                </span>
                                            </div> : courseDetails?.lectures?.map((sect, index) => (
                                                <div className="py-5 px-4 max-sm:px-0 roboto border border-field" key={index}>
                                                    <details className="group">
                                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                                            <div className='flex'>
                                                                <span className="transition group-open:rotate-180">
                                                                    <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                                                                    </svg>
                                                                </span>
                                                                <span className='roboto font-bold'> {sect.section.title}</span>
                                                            </div>
                                                            <div className='roboto text-sm flex items-center font-bold'>
                                                                {sect.section.lessons.length} lectures <svg className='desk-view' width="5" height="4" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0.929688 2.02344V1.76367C0.929688 1.30794 1.07552 0.936523 1.36719 0.649414C1.65885 0.357747 2.05078 0.211914 2.54297 0.211914C3.05339 0.211914 3.45215 0.357747 3.73926 0.649414C4.03092 0.936523 4.17676 1.30794 4.17676 1.76367V2.02344C4.17676 2.47461 4.0332 2.84375 3.74609 3.13086C3.45898 3.41797 3.0625 3.56152 2.55664 3.56152C2.0599 3.56152 1.66341 3.41797 1.36719 3.13086C1.07552 2.84375 0.929688 2.47461 0.929688 2.02344Z" fill="#2D2F31" />
                                                                </svg> 21min
                                                            </div>
                                                        </summary>
                                                        <div className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                                            <div className='flex gap-2 flex-col'>
                                                                <div className='grid grid-cols-12 w-full roboto font-bold'>
                                                                    <div className='col-span-10 flex gap-2 text-left text-ringYellow'>
                                                                        <img src={video_icon} alt="" />
                                                                        Welcome to the Course + User Interface Overview
                                                                    </div>
                                                                    <div className='col-span-1 text-right text-ringYellow '>
                                                                        Preview
                                                                    </div>
                                                                    <div className='col-span-1 text-right text-grey14'>
                                                                        04:22
                                                                    </div>
                                                                </div>
                                                                <div className='grid grid-cols-12 w-full roboto font-bold'>
                                                                    <div className='col-span-10 flex gap-2 text-left '>
                                                                        <img src={video_icon} alt="" />
                                                                        User Research and User Needs
                                                                    </div>
                                                                    <div className='col-span-1 text-right text-ringYellow '>
                                                                        {/* Preview */}
                                                                    </div>
                                                                    <div className='col-span-1 text-right text-grey14'>
                                                                        04:35
                                                                    </div>
                                                                </div>
                                                                <div className='grid grid-cols-12 w-full roboto font-bold'>
                                                                    <div className='col-span-10 flex gap-2 text-left '>
                                                                        <img src={video_icon} alt="" />
                                                                        User Interface Principles
                                                                    </div>
                                                                    <div className='col-span-1 text-right text-ringYellow '>
                                                                        {/* Preview */}
                                                                    </div>
                                                                    <div className='col-span-1 text-right text-grey14'>
                                                                        12:25
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </details>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <button className='font-bold roboto w-full flex justify-center items-center border'>
                                        <h1>
                                            3 more sections
                                        </h1>
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className='col-span-2 max-sm:col-span-6 flex justify-center items-start w-full'>
                            <CourseCard id={courseDetails?._id} poster={courseDetails?.poster[0]} price={courseDetails?.price} name={courseDetails?.title} cid={courseDetails?._id} />
                        </div>
                    </div>}
            </div>
            <Footer />
        </div>
    )
}

export default CourseDetail