import { Link, useLocation } from "react-router-dom";

export default function Breadcrumbs({ courseName }) {
  const crumbNames = [{ from: "payment", to: "Course" }];

  const getCrumbName = (names, crumb) => {
    const obj = crumbNames.find((n) => n.from === crumb);
    if (obj) {
      return obj.to;
    } else {
      return crumb;
    }
  };

  const { pathname } = useLocation();
  let currentLink = "";
  let crumbs = [
    <div className="flex gap-1">
      <Link
        to={"/"}
        className={
          pathname === currentLink ? "pointer-events-none" : "text-btnBlue"
        }
      >
        Home
      </Link>
      {">"}
    </div>,
  ];

  const updatedCrumbs = pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      return (
        <div key={crumb} className="flex gap-1">
          <Link
            to={currentLink}
            className={
              pathname === currentLink ? "pointer-events-none text-grey4" : "text-btnBlue"
            }
          >
            {getCrumbName(crumbNames, crumb)}
          </Link>{" "}
          {">"}
        </div>
      );
    });

  crumbs.push(updatedCrumbs);
  crumbs.push(
    <Link
      to={currentLink}
      className={
        pathname === currentLink ? "pointer-events-none" : "text-btnBlue"
      }
    >
      {courseName}
    </Link>
  );

  return <div className="flex gap-1 text-sm sm:gap-2 sm:text-base">{crumbs}</div>;
}
