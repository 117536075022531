export default function Entrepreneurial() {
  //course objective points
  const COURSE_OBJECTIVES = [
    "Understand the concepts of creating a start-up business",
    "Build confidence and self-esteem",
    "Learn to take on leadership roles",
    "Serve as a model to others and influence positive behaviour",
    "Build a foundation of timeless and lifelong skills",
    "Feel empowered to create their own success story",
    "Have the skillset to direct their own future",
    "Be financially self-sufficient",
  ];

  //course content points
  const COURSE_CONTENT = [
    "The top 10 entrepreneurial qualities",
    "Greetings and introductions in business settings",
    "Learning to spot an opportunity",
    "Drafting a business plan: mission statement, objectives, start-up costs.",
    "Setting and achieving goals",
    "Identifying and managing risk",
    "Marketing a business: website, logo, branding, SEO…",
    "The winning handshake",
    "Initiate, Inspire and Impact: what it means to be an entrepreneur!",
  ];

  return (
    <div className="flex flex-col gap-6 mx-6 my-6 sm:mx-10 md:mx-14">
      {/* course content */}
      <div className="">
        {/* heading */}
        <h3 className="text-3xl font-bold text-btnBlue">Course Content</h3>
        <ul className="flex flex-col gap-2 mt-4 ms-6 text-grey2">
          {COURSE_CONTENT.map((ct, i) => {
            return (
              <li key={i} className="list-disc">
                {ct}
              </li>
            );
          })}
        </ul>
      </div>

      {/* course objectives */}
      <div className="">
        {/* heading */}
        <h3 className="text-3xl font-bold text-btnBlue">Course Objectives</h3>
        <ul className="flex flex-col gap-2 mt-4 ms-6 text-grey2">
          {COURSE_OBJECTIVES.map((ob, i) => {
            return (
              <li key={i} className="list-disc">
                {ob}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
