import React from "react";
import { useNavigate } from "react-router-dom";

const ClassButton = ({ data }) => {
  const navigate = useNavigate();
  return (
    // <button
    //   type="button"
    //   onClick={() => navigate(`/curriculum`, { state: { dataIndex: data.index } })}
    //   className={`werkshops-cons w-[13rem] h-[13rem] bg-white py-[24px] px-[24px] max-sm:py-[20px] max-sm:px-[20px]  shadow_yellow hover:text-white border-btnBlue border hover:bg-${data.index === 0
    //     ? "btnBlue"
    //     : data.index === 1
    //       ? "iconBlue"
    //       : data.index === 2
    //         ? "iconYellow"
    //         : data.index === 3
    //           ? "iconRed"
    //           : data.index === 4
    //             ? "iconPurple"
    //             : "btnBlue"
    //     } rounded-2xl shadow-xl flex flex-col gap-3 justify-center items-center`}
    // >
    //   <img src={data.imgSrc} alt="ukg" />
    //   <h1
    //     className={`urbanist text-md text-${data.className} hover:text-white`}
    //   >
    //     {data.text}
    //   </h1>
    // </button>
    <button
      type="button"
      onClick={() =>
        navigate(`/curriculum`, { state: { dataIndex: data.index } })
      }
      className="w-[200px] h-[200px] p-10 hover:bg-[#B83B5E] bg-[#B83B5E33] rounded-[16px] flex flex-col justify-around items-center grades"
    >
      <img src={data.imgSrc} alt="grades" loading="eager"/>
      <h1 className="manrope text-btnBlue hover:text-white">{data.text}</h1>
    </button>
  );
};

export default ClassButton;
