import AOS from "aos";
import { SpeedInsights } from '@vercel/speed-insights/react';
import "aos/dist/aos.css"; // You can also use <link> for styles
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import AboutUs2 from "./Pages/AboutUs2";
import ContactUs from "./Pages/ContactUs";
import "./Style.css";
import CourseDetail from "./Pages/CourseDetail";
import Courses from "./Pages/Courses";
import Curriculum from "./Pages/Curriculum";
import Home from "./Pages/Home";
import Termsofuse from "./Pages/Termsofuse";
import Testimonials from "./Pages/Testimonials";
import Workshop from "./Pages/Workshop";
import UserContext from "./utils/UserContext";
import Navbar from "./Component/Navbar/Navbar";
import Footer from "./Component/Footer/Footer";
import Payment from "./Pages/Payment";
import Success from "./Pages/success";
import Failed from "./Pages/Failed";

AOS.init();
function App() {
  const [user, setUser] = useState({ email: "" });
  const { token } = useSelector((state) => state.auth);
  const [isLogged, setIsLogged] = useState(false);
  const checkToken = async () => {
    try {
      const result = jwt_decode(token)?.exp < Date.now() / 1000;
      if (result) {
        setIsLogged(false);
      } else {
        setIsLogged(true);
      }
    } catch (e) {
      console.log();
    }
  };

  const formref = React.useRef();

  React.useEffect(() => {
    const handleWheel = (event) => {
      if (event.ctrlKey || event.metaKey) {
        // Prevent scrolling when holding down the Ctrl key (Windows/Linux) or Command key (Mac)
        event.preventDefault();
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("wheel", handleWheel, { passive: false });

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  React.useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="overflow-x-clip">
      <UserContext.Provider
        value={{
          user,
          setUser,
          isLogged,
          setIsLogged,
          formref,
        }}
      >
        <SpeedInsights />
        <BrowserRouter>
          <Routes>
            <Route path="/*">
              <Route index element={<Home />} />
              <Route path="bookworkshop" element={<Workshop />} />
            </Route>
            <Route path="/courses/*">
              <Route index element={<Courses />} />
              <Route path="coursedetail/:id" element={<CourseDetail />} />
            </Route>
            <Route path="/aboutus" element={<AboutUs2 />} />
            <Route path="/curriculum" element={<Curriculum />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contactus" element={<>
              <Navbar />
              <ContactUs />
              <Footer />
            </>
            } />
            <Route path="/termsofuse" element={<Termsofuse />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancle" element={<Failed />} />
            {/* <Route path="/payment" element={<Elements stripe={stripePromise}>
              <PaymentForm />
            </Elements>} /> */}

          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
}

export default App;
