import React from 'react';
import Footer from '../Component/Footer/Footer';
import Navbar from '../Component/Navbar/Navbar';
import { MdErrorOutline } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
const Failed = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 3000);
    }, [navigate])
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar />
            <div className='flex flex-grow justify-center items-center'>
                <h1 className='roboto text-btnBlue font-bold text-5xl p-12 flex flex-row gap-4 items-center justify-center' style={{ color: 'green' }}>
                    <MdErrorOutline style={{ color: 'red', fontSize: '200px' }} />
                    Sorry! payment has not been completed because of some error.
                </h1>
            </div>
            <div className='bottom-0'>
                <Footer />
            </div>
        </div>
    )
}

export default Failed 