import { createSlice } from "@reduxjs/toolkit";
// get-all-circulum
const gradeSlice = createSlice({
    name: 'gradeSlice',
    initialState: {
        isFetching: false,
        error: false,
        errMsg: "",
        grades: [],
        gradesData:[]
    },
    reducers: {
        getAllgradesStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        getAllgradesSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.gradesData = action.payload.circulum.reverse();
        },
        getAllgradesFailure: (state, action) => {
            const { message } = action.payload;
            state.isFetching = false;
            state.error = true;
            state.errMsg = message;
        },
        getCourseStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        getCourseSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.courseDetails = action.payload.course.reverse();
        },
        getCourseFailure: (state, action) => {
            const { message } = action.payload;
            state.isFetching = false;
            state.error = true;
            state.errMsg = message;
        },
    }
});
export const {
    getAllgradesStart,
    getAllgradesSuccess,
    getAllgradesFailure,
    // getCourseStart,
    // getCourseSuccess,
    // getCourseFailure
} = gradeSlice.actions;
export default gradeSlice.reducer;