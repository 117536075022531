import React from "react";

const CardService = ({ heading, text, img }) => {
  return (
    <div className="urbanist flex bg-[#B83B5E33] shadow_yellow  flex-row text-wrap urbanis font-bolder p-5 w-full justify-center items-center gap-5">
      <img
        src={img}
        alt=""
        className="desk-view p-3 m-0 aspect-square max-sm:w-[50px] sm:h-[50px] md:w-[90vw] lg:w-[80px] lg:h-[80px] rounded-2xl"
        style={{
          background: "white",
          border: "1px solid lightgrey",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
        }}
        loading="eager"
      />
      <div className="flex flex-col items-start h-full gap-2 justify-evenly">
        <div className="flex flex-row gap-2">
          <h1
            className="text-xl urbanis max-sm:text-[22px]  text-btnBlue font-bolder max-sm:leading-6"
            style={{ display: "flex", gap: "20px" }}
          >
            <img
              src={img}
              alt=""
              className="phone-view p-3 m-0 aspect-square max-sm:w-[60px] max-sm:h-[60px] w-[60px] h-[60px] rounded-2xl"
              style={{
                background: "white",
                border: "1px solid lightgrey",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
              }}
            />
            <span
              style={{ textAlign: "left", fontWeight: "900" }}
              className="h1-card-home"
            >
              {heading}
            </span>
          </h1>
        </div>
        <p className=" max-sm:text-[15px] max-sm:leading-6 text-grey4">
          {text}
        </p>
      </div>
    </div>
  );
};

export default CardService;
