import arrowRight from "../../Assets/Icons/arrow_right.svg";
import arrowLeft from "../../Assets/Icons/arrow_left.svg";

export default function PrevNextBtn({
  activeCourse,
  setActiveCourse,
  courses,
}) {

  const isNoPrev = activeCourse?.id - 1 < 1;
  const isNoNext = activeCourse?.id + 1 > courses?.length;
  return (
    <div className="flex">
      {/* previous */}
      <div
        className="flex items-center justify-center gap-2 select-none md:gap-3 border-e-2 pe-3 border-x-grey4 hover:cursor-pointer"
        onClick={() => {
          const course = courses.find((c) => c.id === activeCourse.id - 1);
          if (course) {
            setActiveCourse(course);
          }
        }}
      >
        <img
          alt="arrow left"
          src={arrowLeft}
          className="h-3 md:h-4 hover:scale-110"
        />
        <span
          className={`text-sm ${isNoPrev ? "font-light" : "font-normal"} ${
            isNoPrev ? "text-grey4" : "text-btnBlue"
          } sm:text-base lg:text-xl`}
        >
          Previous
        </span>
      </div>
      {/* next */}
      <div
        className="flex items-center justify-center gap-2 select-none md:gap-3 ps-3 hover:cursor-pointer"
        onClick={() => {
          const course = courses.find((c) => c.id === activeCourse.id + 1);
          if (course) {
            setActiveCourse(course);
          }
        }}
      >
        <span
          className={`text-sm ${
            isNoNext ? "font-light" : "font-normal"
          } sm:text-base lg:text-xl ${
            isNoNext ? "text-grey4" : "text-btnBlue"
          }`}
        >
          Next
        </span>
        <img
          alt="arrow right"
          src={arrowRight}
          className="h-3 md:h-4 hover:scale-110"
        />
      </div>
    </div>
  );
}
