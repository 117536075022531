import React, { useCallback, useEffect, useState } from "react";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import Swal from "sweetalert2";
import axios from "../utils/axiosInstance";
import banner from "../Assets/Images/bannerPayment.webp";
import fallbackImg from "../Assets/Images/courses/fallbackImg.png";
import { IoPersonSharp } from "react-icons/io5";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import Navs from "../Component/Course/Navs";
import BoutiqueGroup from "../Component/Course/Courses/BoutiqueGroup";
import FullDayVisit from "../Component/Course/Courses/FullDayVisit";
import Classroom from "../Component/Course/Courses/Classroom";
import Parental from "../Component/Course/Courses/Parental";
import Entrepreneurial from "../Component/Course/Courses/Entrepreneurial";
import OneOnOne from "../Component/Course/Courses/OneOnOne";
import PrevNextBtn from "../Component/Course/PrevNextBtn";
import Breadcrumbs from "../Component/Course/Breadcrumbs";
import entrepreneurialImg from "../Assets/Images/courses/entrepreneural.min.jpg";
import classRoomImg from "../Assets/Images/courses/classroom_visit.jpeg";
import fullDayVisitImg from "../Assets/Images/courses/fullday_visit.min.jpg";
import boutiqueImg from "../Assets/Images/courses/boutique_group.jpeg";
import parentalImg from "../Assets/Images/courses/parental.min.jpg";
import oneOnOneImg from "../Assets/Images/courses/one_on_one.min.jpeg";

// import { useSelector } from 'react-redux';
// import CartContext from '../utils/CartContext';
// import card from '../Assets/Images/cards.png'
// import lock from '../Assets/Icons/lock.svg';
// import question from '../Assets/Icons/question.svg';
// import paypal from '../Assets/Images/paypal.png';
// import affirm from '../Assets/Images/affirm.png';
function getBaseAddress() {
  return window.location.protocol + "//" + window.location.host;
}

const Payment = () => {
  const [activeCourse, setActiveCourse] = useState({});
  const [loading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState({ isLoading: true, src: fallbackImg });
  const [formdata, setFormdata] = useState({
    name: "",
    number: 0,
    email: "",
  });
  const location = useLocation();
  const obj = location.state && location.state.data.course;
  const courses = location.state?.data.courses;

  useEffect(() => {
    setActiveCourse(obj);
  }, [obj]);

  const DETAILS = [
    { id: 1, element: <FullDayVisit /> },
    { id: 2, element: <Classroom /> },
    { id: 3, element: <Parental /> },
    { id: 4, element: <BoutiqueGroup /> },
    { id: 5, element: <Entrepreneurial /> },
    { id: 6, element: <OneOnOne /> },
  ];
  // DETAILS.find((d) => d.id === activeCourse?.id)?.element
  const makePayment = async () => {
    setLoading(true);
    if (!isEmail(formdata.email)) {
      Swal.fire({
        icon: "error",
        title: "Validation error",
        text: "Please enter a proper email address",
      });
      setLoading(false);
    }
    if (formdata.number < 8) {
      Swal.fire({
        icon: "error",
        title: "Validation error",
        text: "Please enter a proper mobile number",
      });
      setLoading(false);
    }
    if (!formdata.name) {
      Swal.fire({
        icon: "error",
        title: "Validation error",
        text: "Please enter a your name",
      });
      setLoading(false);
    }
    if (formdata.name && formdata.email && formdata.number) {
      const stripe = await loadStripe(process.env.REACT_APP_PUB_KEY);
      const body = {
        products: [
          {
            dish: activeCourse?.name,
            price: activeCourse?.cost,
            qnty: "1",
          },
        ],
        customerEmail: formdata.email,
        customerName: formdata.name,
        customerPhone: formdata.number,
        base: getBaseAddress(),
      };
      // const headers = {
      //     "Content-Type": "application/json"
      // }
      const response = await axios.post(
        "/api/order/create-checkout-session",
        body
      );
      // const response = await fetch("http://localhost:5000/api/order/create-checkout-session", {
      //     method: "POST",
      //     headers: headers,
      //     body: JSON.stringify(body)
      // });
      const session = await response.data;

      const result = stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.log(result.error);
      }
    }
  };

  //detail navs
  const DETAIL_NAVS = [
    { id: 1, label: "Description" },
    { id: 2, label: "Curriculum" },
    { id: 3, label: "Social Grades" },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const handleImageLoad = useCallback(() => {
    const images = [
      {
        img: fullDayVisitImg,
        id: 1,
      },
      {
        img: classRoomImg,
        id: 2,
      },
      {
        img: parentalImg,
        id: 3,
      },
      {
        img: boutiqueImg,
        id: 4,
      },
      {
        img: entrepreneurialImg,
        id: 5,
      },
      {
        img: oneOnOneImg,
        id: 6,
      },
    ];
    setTimeout(() => {
      setImgSrc({ isLoading: false, src: images.at(activeCourse?.id - 1).img });
    }, 500);
  }, [activeCourse.id]);

  useEffect(() => {
    handleImageLoad();
    return () => {
      setImgSrc({ isLoading: true, src: fallbackImg });
    };
  }, [handleImageLoad, activeCourse?.id]);

  return (
    <div className="flex flex-col justify-start min-h-screen">
      <Navbar />
      <div className="flex items-start justify-center flex-grow">
        {!true ? (
          <div>
            <h1 className="p-12 text-3xl font-bold roboto text-btnBlue">
              Your Cart is Empty
            </h1>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-start w-full">
            <div className="flex items-center justify-center w-full workshop-gradient">
              <div className="flex items-center justify-start w-full my-3 sm:px-10 lg:px-24">
                {/* breadcrumbs */}
                <Breadcrumbs courseName={activeCourse?.name} />
              </div>
              <div className="flex items-center justify-end w-full px-4 my-3 sm:px-10 lg:px-24">
                {/* prev-next navigation */}
                <PrevNextBtn
                  activeCourse={activeCourse}
                  setActiveCourse={setActiveCourse}
                  courses={courses}
                />
              </div>
            </div>
            {/* breadcrums & prev-next navigation buttons */}

            {/* //details */}
            <div className="w-full mt-2">
              {/* <Navs navs={DETAIL_NAVS} /> uncomment this if you want nav tabs instead heading */}
              {/* heading */}
              <div className="flex justify-center py-1 mb-9 bg-backOrange">
                <h1 className="my-3 text-2xl font-bold text-white roboto">
                  Course Description
                </h1>
              </div>
              {/* info */}
              {DETAILS.find((d) => d.id === activeCourse?.id)?.element}
            </div>
            {/* payment details */}

            <div className="flex flex-col justify-between mx-4 md:flex-row lg:mx-24 sm:mx-10 gap-14">
              <div className="w-full">
                <img
                  loading="lazy"
                  src={imgSrc.src}
                  className={`object-cover w-full md:h-[97%] rounded-xl border-2 aspect-square ${
                    imgSrc.isLoading ? "pulse-img" : "border-backOrange"
                  }`}
                  alt="course prev"
                />
              </div>
              {/* course details */}
              <div className="flex flex-col gap-5 ">
                {/* info */}
                <div className="flex flex-col gap-4 ">
                  <h1 className="text-3xl font-bold text-btnBlue georgia">
                    {activeCourse?.name}
                  </h1>
                  <p className="text-xl font-bold sm:text-2xl roboto">
                    ${activeCourse?.cost}
                  </p>
                  <p className="text-sm font-semibold text-justify text-[#212529CC]">
                    Our financial literacy workshops are not just another class
                    for kids. They are programs designed to empower your child
                    with the skills required to understand the way money works
                    and how it relates to future stability and happiness. A
                    foundation of financial literacy taught in childhood is
                    priceless and a valuable lifelong investment. In our whole
                    scope of enrichment, financial competency is the one thing
                    that we can offer our children that is not common and yet is
                    integral to their success.
                  </p>
                </div>
                {/* personal details */}
                <div className="flex flex-col gap-6 my-4 ">
                  <div>
                    <h1 className="text-2xl font-bold">Personal Details</h1>
                    {/* <p className="text-sm font-normal text-[#000000BF]">
                      All details are encrypted.
                    </p> */}
                  </div>
                  {/* inputs */}
                  <div className="flex flex-col justify-around h-full gap-4 p-4 border rounded border-btnBlue roboto">
                    {/* name */}
                    <div className="flex flex-row items-center justify-between w-full px-4 py-4 border rounded-lg hover:rounded-md border-btnBlue max-sm:py-1">
                      <IoPersonSharp className="mr-2 text-lg text-grey4" />
                      <input
                        onChange={(e) =>
                          setFormdata({
                            ...formdata,
                            name: e.target.value,
                          })
                        }
                        type="text"
                        className="w-full focus:outline-none"
                        placeholder="Enter your name"
                      />
                    </div>
                    {/* phone number */}
                    <div className="flex flex-row items-center justify-between w-full px-4 py-4 border rounded-lg hover:rounded-md border-btnBlue max-sm:py-1">
                      <MdOutlinePhoneIphone className="mr-2 text-lg text-grey4" />
                      <input
                        onChange={(e) =>
                          setFormdata({
                            ...formdata,
                            number: e.target.value,
                          })
                        }
                        type="number"
                        className="w-full focus:outline-none"
                        placeholder="Enter your phone number"
                      />
                    </div>
                    {/* email */}
                    <div className="flex flex-row items-center justify-between w-full px-4 py-4 border rounded-lg hover:rounded-md border-btnBlue max-sm:py-1">
                      <CiMail className="mr-2 text-lg text-grey4" />
                      <input
                        onChange={(e) =>
                          setFormdata({
                            ...formdata,
                            email: e.target.value,
                          })
                        }
                        type="email"
                        className="w-full focus:outline-none"
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>
                  <button
                    disabled={loading}
                    onClick={makePayment}
                    className="flex items-center justify-center w-full px-12 py-4 text-xl font-bold text-white roboto bg-btnBlue disabled:bg-grey2 rounded-xl"
                  >
                    {loading && (
                      <div className="w-8 h-8 mr-2 bg-transparent border border-t-8 rounded-full animate-spin" />
                    )}
                    {loading
                      ? "Wait! Please do-not close or refresh"
                      : "Book Now"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bottom-0">
        <Footer />
      </div>
    </div>
  );
};

export default Payment;
