import axios from '../utils/axiosInstance';

import {
    loginStart,
    loginSuccess,
    loginFailure,
    UpdateStart,
    UpdateSuccess,
    UpdateFailure,
    registerSuccess,
    registerStart,
    registerFailure,
} from "./Slices/AuthSlice";
import {
    resetStart,
    resetCodeSendSuccess,
    resetCodeSendFailure,
    validateCodeStart,
    validateCodeSuccess,
    validateCodeFailure,
    setPasswordStart,
    setPasswordSuccess,
    setPasswordFailure,
} from './Slices/ResetPasswordSlice';
import {
    getAllStart,
    getAllSuccess,
    getAllFailure,
    getCategoriesStart,
    getCategoriesSuccess,
    getCategoriesFailure,
    getCourseStart,
    getCourseSuccess,
    getCourseFailure
} from './Slices/CourseSlice';
import {
    getTestimonialsStart,
    getTestimonialsSuccess,
    getTestimonialsFailure
} from './Slices/TestimonialSlice';

import {
    getimagesStart,
    getimagesSuccess,
    getimagesFailure,
} from './Slices/StaticSlice';

import {
    addCourse,
    removeCourse,
    addCourseCart,
    calcPrice
} from './Slices/CartSlice';

import {
    workshopStart,
    workshopSuccess,
    workshopFailure,
} from './Slices/WorkshopSlice';

import {
    sendQueryStart,
    sendQuerySuccess,
    sendQueryFailure,
    sendEnrollStart,
    sendEnrollSuccess,
    sendEnrollFailure,
} from './Slices/ContactSlice';

import {
    getAllgradesStart,
    getAllgradesSuccess,
    getAllgradesFailure,
} from './Slices/GradeSlice';

const token = localStorage.getItem("userToken");
export const login = async (dispatch, user) => {
    dispatch(loginStart());

    const { email, password } = user;

    try {
        const { data } = await axios.post("/api/user/login", { email, password });
        dispatch(loginSuccess(data));
    } catch (error) {
        dispatch(loginFailure(error?.response?.data?.error));
    }
};


export const signup = async (dispatch, user) => {
    dispatch(registerStart());
    const { firstname, lastname, mobile_no, email, password } = user;

    try {
        const { data } = await axios.post("/api/user/register", { firstname, lastname, mobile_no, email, password });
        dispatch(registerSuccess(data));
    } catch (error) {
        dispatch(registerFailure(error?.response?.data?.error));
    }
};

export const sendPasswordCode = async (dispatch, user) => {
    dispatch(resetStart());
    const { email } = user;

    try {
        const { data } = await axios.post("/api/user/send-resetpassword-code", { email });
        dispatch(resetCodeSendSuccess(data));
    } catch (error) {
        dispatch(resetCodeSendFailure(error?.response?.data?.error));
    }
}

export const validateCode = async (dispatch, user) => {
    dispatch(validateCodeStart());
    const { code, email } = user;

    try {
        const { data } = await axios.post("/api/user/validate-code", { code, email });
        dispatch(validateCodeSuccess(data));
    } catch (error) {
        dispatch(validateCodeFailure(error?.response?.data?.error));
    }
}

export const setPassword = async (dispatch, user) => {
    dispatch(setPasswordStart());
    const { email, password, cpassword } = user;

    try {
        const { data } = await axios.post("/api/user/reset-password", { email, newPassword: password, confirmPassword: cpassword });
        dispatch(setPasswordSuccess(data));
    } catch (error) {
        dispatch(setPasswordFailure(error?.response?.data?.error));
    }
}

export const updateProfile = async (dispatch, user) => {
    dispatch(UpdateStart());
    const { firstname, lastname, mobile_no, email } = user;

    try {
        const { data } = await axios.put("/api/user/update-profile", { firstname, lastname, mobile_no, email }, {
            headers: {
                Authorization: `${token}`,
            },
        }
        );
        localStorage.removeItem('user');
        dispatch(UpdateSuccess(data));
    } catch (error) {
        dispatch(UpdateFailure(error?.response?.data?.error));
    }
};

export const getAllCourses = async (dispatch) => {
    dispatch(getAllStart());
    try {
        const { data } = await axios.get("/api/course/getallcourse")
        dispatch(getAllSuccess(data));
    } catch (error) {
        dispatch(getAllFailure(error?.response?.data?.error));
    }
}

export const getAllcirculum = async (dispatch) => {
    dispatch(getAllgradesStart);
    try {
        const { data } = await axios.get("/api/circulum/get-all-circulum");
        dispatch(getAllgradesSuccess(data));
    } catch (error) {
        dispatch(getAllgradesFailure(error?.response?.data?.error));
    }
}

export const getTestimonials = async (dispatch) => {
    getAllcirculum(dispatch);
    dispatch(getTestimonialsStart());
    try {
        const { data } = await axios.get("/api/testimonial/get-all-testimonial")
        dispatch(getTestimonialsSuccess(data));
    } catch (error) {
        dispatch(getTestimonialsFailure(error?.response?.data?.error));
    }
}

export const getAllCategories = async (dispatch) => {
    dispatch(getCategoriesStart());
    try {
        const { data } = await axios.get("/api/category/get-all-categories")
        dispatch(getCategoriesSuccess(data));
    } catch (error) {
        dispatch(getCategoriesFailure(error?.response?.data?.error));
    }
}

export const getAllimages = async (dispatch) => {
    dispatch(getimagesStart());
    try {
        const { data } = await axios.get("/api/images/get-all-images")
        dispatch(getimagesSuccess(data));
        console.log(data)
    } catch (error) {
        dispatch(getimagesFailure(error?.response?.data?.error));
    }
}

export const getCourse = async (dispatch, data) => {
    dispatch(getCourseStart());
    const { id } = data;
    try {
        const { data } = await axios.get(`/api/course/getcourse/${id}`,
            {
                headers: {
                    Authorization: `${token}`,
                },
            })
        console.log(data);
        dispatch(addCourseCart({ name: data?.course?.title, price: data?.course?.price }));
        dispatch(calcPrice(data?.course?.price));
        dispatch(getCourseSuccess(data));
    } catch (error) {
        dispatch(getCourseFailure(error?.response?.data?.error));
    }
}

export const AddCourse = async (dispatch, data) => {
    // dispatch(addCourse());
    console.log(data);
    const { id } = data;
    dispatch(addCourse(id));
}

export const RemoveCourse = async (dispatch, data) => {
    // dispatch(addCourse());
    console.log(data);
    const { index } = data;
    dispatch(removeCourse(index));
}

export const Bookworkshop = async (dispatch, formdata) => {
    dispatch(workshopStart());
    try {
        const { data } = await axios.post('/api/workshop/bookworkshop', formdata);
        dispatch(workshopSuccess(data));
    } catch (error) {
        dispatch(workshopFailure(error?.response?.data?.error));
    }
}

export const sendQuery = async (dispatch, formdata) => {
    console.log(formdata);
    dispatch(sendQueryStart());
    const { name, email, phone, message } = formdata;
    try {
        const { data } = await axios.post("api/query/submit-query", { name, email, phone, message })
        dispatch(sendQuerySuccess(data));
    } catch (error) {
        dispatch(sendQueryFailure(error?.response?.data?.error));
    }
}

export const Enroll = async (dispatch, formdata) => {
    dispatch(sendEnrollStart());
    const { name, email, desc, mobile, grade } = formdata;
    try {
        const { data } = await axios.post("api/enroll/enroll-course", { name, email, description: desc, phone:mobile, grade })
        dispatch(sendEnrollSuccess(data));
    } catch (error) {
        dispatch(sendEnrollFailure(error?.response?.data?.error));
    }
}




