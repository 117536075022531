export default function BoutiqueGroup() {
  //benefits list
  const BENEFITS = [
    "Enhanced financial literacy and confidence in managing money",
    "Development of practical money management skills and habits",
    "Empowerment to make informed financial decisions",
    "Increased awareness of financial opportunities and risks",
    "Peer support and networking opportunities",
    "Preparation for financial independence and success in adulthood",
  ];

  //   course structure
  const COURSE_STRUCTURE = [
    {
      title: "Small Group Setting",
      description:
        "The program accommodates small groups of 5-10 youth participants to ensure personalized attention and foster a supportive learning environment where participants can engage actively in discussions and activities.",
    },
    {
      title: "Customized Curriculum",
      description:
        "The curriculum is carefully crafted to address the unique needs, interests, and challenges of youth participants. Key topics covered include:",
      list: [
        "Basics of budgeting and financial planning",
        "Understanding banking services and financial products",
        "Managing income, expenses, and savings",
        "Building and maintaining good credit",
        "Exploring investment options and financial goals",
        "Creating financial freedom",
        "Responsible use of credit cards, loans and debt",
      ],
    },
    {
      title: "Interactive Workshops and Activities",
      description:
        "The program incorporates a variety of interactive workshops, activities, and simulations to make learning about finances engaging and relatable for youth. Activities may include:",
      list: [
        "Budgeting games and challenges to help participants understand the importance of tracking expenses and setting financial goals.",
        "Role-playing scenarios where participants practice making financial decisions, such as choosing between wants and needs, negotiating prices, or managing unexpected expenses.",
        "Group discussions and case studies exploring real-life financial dilemmas and solutions.",
        "Hands-on activities, such as opening a bank account, comparing financial products, or conducting basic investment research.",
      ],
    },
    {
      title: "Personalized Goal Setting and Action Plans",
      description:
        "Participants are encouraged to set personalized financial goals based on their aspirations and circumstances. Facilitators work with each participant to develop action plans outlining steps to achieve their goals, whether it's saving for university, starting a business, or becoming financially independent.",
    },
    {
      title: "Ongoing Support and Resources",
      description:
        "The program provides participants with access to additional resources, tools, and support beyond the workshop sessions.",
    },
  ];

  return (
    <div className="flex flex-col gap-6 mx-6 my-6 sm:mx-10 md:mx-14">
      {/* introduction */}
      <div className="">
        {/* heading */}
        <h3 className="text-3xl font-bold text-btnBlue">Introduction</h3>
        <div className="flex flex-wrap gap-5 mt-4 font-semibold text-grey2">
          "Financially Savvy Youth" is a boutique group financial literacy
          program tailored specifically for youth aged 13-18. This program
          offers a personalized and interactive learning experience designed to
          equip participants with essential money management skills, cultivate a
          positive attitude towards finances, and empower them to make informed
          financial decisions as they transition into adulthood.
        </div>
      </div>
      {/* course structure */}
      <div className="">
        {/* heading */}
        <h3 className="text-3xl font-bold text-btnBlue">Course Structure</h3>
        <ul className="flex flex-col gap-2 mt-4 list-decimal ms-6">
          {COURSE_STRUCTURE.map((cr, i) => {
            return (
              <li key={i}>
                <span className="font-semibold">{cr.title}: </span>
                <span className=" text-grey2">{cr.description}</span>
                <ul className="flex flex-col gap-1 mt-2 list-disc ms-12 text-grey2">
                  {cr.list &&
                    cr.list.map((be, j) => {
                      return <li key={j}>{be}</li>;
                    })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
      
      {/* benefits */}
      <div className="">
        {/* heading */}
        <h3 className="text-3xl font-bold text-btnBlue">Benefits</h3>
        <ul className="flex flex-col gap-2 mt-4 ms-6 text-grey2">
          {BENEFITS.map((ben, i) => {
            return (
              <li key={i} className="list-disc">
                {ben}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
