import { createSlice } from "@reduxjs/toolkit";

const workshopSlice = createSlice({
    name: "auth",
    initialState: {
        isFetching: false,
        error: false,
        errMsg: "",
    },
    reducers: {
        workshopStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        workshopSuccess: (state) => {
            state.error = false;
            state.isFetching = false;
        },
        workshopFailure: (state, action) => {
            state.errMsg = action.payload;
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    workshopStart,
    workshopSuccess,
    workshopFailure,
} = workshopSlice.actions;
export default workshopSlice.reducer;
