import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
// import courseImg from '../Assets/Images/about_us_img2.webp';
import { Enroll } from '../Redux/ApiCalls';
import { useSelector, useDispatch } from 'react-redux';
import { isValidEmail } from '../utils/functions';
import img1 from '../Assets/Images/curimg1.jpg';
import img2 from '../Assets/Images/curimg2.jpg';
import img3 from '../Assets/Images/curimg3.jpeg';
import img4 from '../Assets/Images/curimg4.jpeg';
import img5 from '../Assets/Images/curimg5.jpeg';
import imgFor1and2 from '../Assets/Images/curImages/forGrade1and2.webp';
import imgFor3 from '../Assets/Images/curImages/forGrade3.webp';
import imgFor4 from '../Assets/Images/curImages/forGrade4.webp';
import imgFor5 from '../Assets/Images/curImages/forGrade5.webp';
import imgFor6 from '../Assets/Images/curImages/forGrade6.webp';
import imgFor7 from '../Assets/Images/curImages/forGrade7.webp';
import imgFor10 from '../Assets/Images/curImages/for10Grade.webp'
// import { errorHandler } from '../utils/errorHandler';
// import { useNavigate } from 'react-router-dom';
// import CartContext from '../utils/CartContext';
// import {    useDispatch, useSelector     } from 'react-redux';
// import { addCourse } from '../Redux/Slices/CartSlice';
// import { AddCourse } from '../Redux/ApiCalls';
// import { calcPrice } from '../Redux/Slices/CartSlice';

const CourseBuyCard = (props) => {
    const { isFetching, error } = useSelector(state => state.contact);
    const dispatch = useDispatch();
    // const {  } = useSelector((state) => state.course)
    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const { setCart } = useContext(CartContext);
    useEffect(() => {

        return () => {

        }
    }, [])

    function getRandomIndex(arr) {
        return Math.floor(Math.random() * arr.length);
    }
    const imgs = [img1, img2, img3, img4, img5];
    const handleBuyNow = async () => {
        // eslint-disable-next-line no-unused-vars
        const { value: formValues } = await Swal.fire({
            title: 'Enter Your Details',

            html:
                '<input type="text"  required={true} id="Name" class="swal2-input" placeholder="Name" className="w-full">' +
                '<input type="email" required={true} id="Email" class="swal2-input" placeholder="Email" className="w-full">' +
                '<input type="number" required={true} id="Mobile_Number" class="swal2-input" placeholder="Mobile Number" className="w-full">' +
                '<input type="text" required={true} id="Description" class="swal2-input" placeholder="Description" className="w-full">',
            focusConfirm: false,
            confirmButtonText:
                'Submit',
            showCloseButton: true,
            preConfirm: async () => {
                const name = document.getElementById('Name')?.value
                const email = document.getElementById('Email')?.value
                const desc = document.getElementById('Description')?.value
                const mobile = document.getElementById('Mobile_Number')?.value
                const grade = props.title
                if (!name || !email || !desc || !mobile || !grade) {
                    return Swal.fire({
                        icon: "error",
                        title: "Validation error",
                        text: "Please fill all fields!",
                    });
                } else if (mobile.length < 8) {
                    return Swal.fire({
                        icon: "error",
                        title: "Validation error",
                        text: "Please fill mobile number!",
                    });
                } else if (!isValidEmail(email)) {
                    return Swal.fire({
                        icon: "error",
                        title: "Validation error",
                        text: "Please enter proper email address!",
                    });
                }
                await Enroll(dispatch, {
                    name: document.getElementById('Name')?.value,
                    email: document.getElementById('Email')?.value,
                    desc: document.getElementById('Description')?.value,
                    mobile: document.getElementById('Mobile_Number')?.value,
                    grade: props.title
                });
                if (error) {
                    return Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Cannot Submit your form currently!",
                    });
                }
                return Swal.fire(
                    `Thank you for enrolling into our courses.Our team will be in touch with you shortly.} .`
                )
            }
        })
    }
    const imager = () => {
        if (props.index === 0 || props.index === 1) {
            return imgFor1and2;
        } else if (props.index === 2) {
            return imgFor3;
        } else if (props.index === 3) {
            return imgFor4;
        } else if (props.index === 4) {
            return imgFor5;
        } else if (props.index === 5) {
            return imgFor6;
        } else if (props.index === 6) {
            return imgFor7;
        } else if (props.index > 6 && props.index < 9) {
            return imgFor10;
        } else {
            return imgs[getRandomIndex(imgs)];
        }
    }
    return (
        // <div className='flex flex-col max-sm:flex-row items-center max-sm:grid grid-cols-2 '>
        //     <img src="https://th.bing.com/th/id/OIP.TV2Qx00U9v5GzvD0YYxCDQHaET?rs=1&pid=ImgDetMain" alt="back" className='lg:w-2/3 rounded-t-xl max-sm:order-2' />
        //     <div className='phone-view w-full'>
        //         <h1 className='poppins font-bold text-xl max-sm:text-lg'>
        //             Name
        //         </h1>
        //         <p className='poppins font-bold text-sm max-sm:text-xs'>
        //             "Instilling essential financial literacy skills in students to cultivate responsible money management and empower them for a financially secure future."
        //             {/* <b className='text-ringYellow'>Learn more</b> */}
        //         </p>
        //     </div>
        //     <div className='lg:p-5 lg:w-2/3 max-sm:w-full border border-backOrange bg-[#d6e6ff] rounded-b-xl justify-center max-sm:col-span-2 gap-4 flex flex-col max-sm:flex-col flex-wrap'>
        //         <h1 className='urbanis font-bold text-xl desk-view text-btnBlue'>
        //             Grade 1
        //         </h1>
        //         <p className='poppins font-bold text-sm desk-view'>
        //             "Instilling essential financial literacy skills in students to cultivate responsible money management and empower them for a financially secure future."
        //             {/* <b className='text-ringYellow'>Learn more</b> */}
        //         </p>

        //         <button onClick={handleBuyNow} className='py-4 px-12 roboto w-full bg-btnBlue rounded-xl text-white font-bold text-xl '>
        //             Enroll Now
        //         </button>
        //         {/* <div>
        //             <button onClick={handleAddToCart} className='py-4 px-12 roboto w-full rounded-xl text-grey9 border border-grey9 font-bold text-xl'>
        //                 Add To Cart
        //             </button>
        //         </div> */}
        //     </div>
        // </div>
        <div className='grid gap-4 lg:grid-cols-1 md:grid-cols-2 sm:grid-cols-2 lg:w-10/12 md:w-full max-sm:w-full shadow-2xl p-5 rounded-xl'>
            <img src={imager()} alt="back" className='lg:rounded-t-xl object-cover md:rounded-l-lg max-sm:rounded-l-lg  aspect-square' />
            <div className='flex gap-4 flex-col rounded-b-xl'>
                <h1 className='poppins font-bold text-xl max-sm:text-lg'>
                    {props.title}
                </h1>
                <p className='poppins font-bold lg:text-xs xl:lg md:text-md max-sm:text-xs'>
                    {props.desc === "N/A" ?
                        <p className="manrope font-bold text-btnBlue text-xl whitespace-pre-wrap">
                            Elevate your child's future with our comprehensive financial literacy course, empowering them with essential money management skills.
                        </p> :
                        <p className="manrope font-bold text-btnBlue text-xl whitespace-pre-wrap">
                            {props.desc}
                        </p>
                    }
                    {/* {props.desc !== "N/A" && (
                        <p className="manrope font-bold text-btnBlue text-xl whitespace-pre-wrap">
                            {props.desc}
                        </p>
                    )} */}
                </p>
                <button disabled={isFetching} onClick={handleBuyNow} className='disabled:bg-subHeading py-4 px-12 roboto w-full bg-btnBlue rounded-xl text-white font-bold text-xl '>
                    Enroll Now
                </button>
            </div>
        </div>
    )
}

export default CourseBuyCard