import React from "react";
// import { useNavigate } from "react-router-dom";

const Tag = ({ icon, label, index }) => {
  // const navigate = useNavigate();
  return (
    <button type='button'
      // onClick={() =>
      //   navigate(`/curriculum`, { state: { dataIndex: index < 3 ? 0 : index === 3 ? 1 : index === 4 ? 2 : index === 5 ? 3 : index === 6 ? 4 : 5 } })
      // }
      className="px-5 zoom rounded-xl h-[70px] shadow_yellow gap-5 border border-[#B83B5E] bg-[#B83B5E1A] flex items-center justify-content-center flex-row text-wrap max-sm:min-w-full max-w-sm text-left">
      {icon} <span className="urbanis text-lg"> {label}</span>
    </button>
  );
};

export default Tag;
