export default function Parental() {
  //benefits list
  const BENEFITS = [
    "Empowering parents to be proactive in teaching financial literacy to their children.",
    "Equipping parents with the knowledge and tools to support their children's financial education journey.",
    "Fostering open communication between parents and children about money matters.",
    "Instilling lifelong money management skills and habits in children.",
    "Setting children on the path towards financial independence and success.",
  ];

  //   course structure
  const COURSE_STRUCTURE = [
    {
      title: "Introduction",
      description:
        "The session begins with an introduction to the importance of financial literacy for children and its long-term impact on their financial well-being. The facilitator highlights the role of parents in shaping their children's attitudes and behaviors towards money.",
    },
    {
      title: "Understanding Financial Literacy",
      description:
        "Participants are provided with an overview of what financial literacy entails, including concepts such as budgeting, saving, spending wisely, earning money, and giving back. The facilitator emphasizes the need to start teaching these concepts at an early age and integrate them into everyday life.",
    },
    {
      title: "Age-Appropriate Financial Education",
      description:
        "Parents learn about age-appropriate financial education strategies tailored to their children's developmental stages. Topics covered may include:",
      list: [
        "Teaching young children the value of money through simple activities like counting coins or playing store.",
        "Introducing older children to concepts like budgeting, goal setting, and distinguishing needs from wants.",
        "Discussing more complex topics with teenagers, such as saving for university, managing bank accounts, and understanding credit.",
      ],
    },
    {
      title: "Teaching Tools and Resources",
      description:
        "The session provides parents with practical tools and resources to facilitate financial education at home. This may include recommended books, websites, mobile apps, and educational games that make learning about money engaging and fun for children.",
    },
    {
      title: "Setting Financial Goals",
      description:
        "Parents learn how to help their children set realistic financial goals and create a plan to achieve them. The facilitator discusses the importance of involving children in decision-making processes and empowering them to take ownership of their financial future.",
    },
    {
      title: "Open Discussion and Q&A",
      description:
        "The session concludes with an open discussion where parents can share their experiences, ask questions, and seek advice from the facilitator and other participants. Common concerns and challenges related to teaching financial literacy to children are addressed, and practical solutions are offered.",
    },
  ];

  return (
    <div className="flex flex-col gap-6 mx-6 my-6 sm:mx-10 md:mx-14">
      {/* introduction */}
      <div className="">
        {/* heading */}
        <h3 className="text-3xl font-bold text-btnBlue">Introduction</h3>
        <div className="flex flex-wrap gap-5 mt-4 font-semibold text-grey2">
          "Building Strong Foundations" is a parent information session designed
          to equip parents with the knowledge and resources to foster financial
          literacy in their children. The session aims to empower parents to
          instill healthy financial habits, promote responsible money
          management, and prepare their children for financial independence in
          the future.
        </div>
      </div>
      {/* course structure */}
      <div className="">
        {/* heading */}
        <h3 className="text-3xl font-bold text-btnBlue">Course Structure</h3>
        <ul className="flex flex-col gap-2 mt-4 list-decimal ms-6">
          {COURSE_STRUCTURE.map((cr, i) => {
            return (
              <li key={i}>
                <span className="font-semibold">{cr.title}: </span>
                <span className=" text-grey2">{cr.description}</span>
                <ul className="flex flex-col gap-1 mt-2 list-disc ms-12 text-grey2">
                  {cr.list &&
                    cr.list.map((be, j) => {
                      return <li key={j}>{be}</li>;
                    })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>

      {/* benefits */}
      <div className="">
        {/* heading */}
        <h3 className="text-3xl font-bold text-btnBlue">Benefits</h3>
        <ul className="flex flex-col gap-2 mt-4 ms-6 text-grey2">
          {BENEFITS.map((ben, i) => {
            return (
              <li key={i} className="list-disc">
                {ben}
              </li>
            );
          })}
        </ul>
      </div>

      {/* conclusion */}
      <div>
        <h3 className="text-3xl font-bold text-btnBlue">Evaluation </h3>
        <p className="mt-4 text-grey2">
          Feedback forms are distributed at the end of the session
          to gather input from participants about the effectiveness of the
          information presented and the usefulness of the resources provided.
          Suggestions for improvement are welcomed to enhance future sessions
          and better meet the needs of parents seeking to promote financial
          literacy in their children.
        </p>
      </div>
    </div>
  );
}
