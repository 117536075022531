import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../Logo";
import "./Footer.css";
import { MdOutlineMail } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import tweeter from '../../Assets/Icons/tweeter.svg';
import facebook from '../../Assets/Icons/facebook.svg';
import linkedin from '../../Assets/Icons/linkedin.svg';
import instagram from '../../Assets/Icons/fi_1384015.svg'
import AnchorLink from "react-anchor-link-smooth-scroll";

const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="h-max">
      <div className="flex items-start justify-center w-screen px-2 py-5 urbanist bg-btnBlue h-1/2">
        {/* <div className="grid grid-cols-2 gap-10 text-white max-sm:grid-cols-1 inter"> */}
        <div className="flex items-start justify-center w-full gap-4 text-white lg:justify-around sm:gap-8 md:gap-10 inter">
          {/* logo
          <div className="flex flex-col items-center justify-center px-4 mt-2">
            <div className="text-xl mali">
              <Logo />
            </div>
            <p className="mt-2 text-xs font-semibold text-center sm:text-sm md:text-base inter">
              Setting kids up for success
            </p>
          </div> */}

          {/* links */}
          <div className="flex justify-center w-full gap-4 mx-2 sm:gap-8 md:gap-10 lg:justify-evenly">
            {/* logo */}
            <div className="flex flex-col items-center justify-start px-4 mt-2">
              <div className="text-xl mali">
                <Logo />
              </div>
              <p className="mt-2 text-[5px] font-semibold text-center sm:text-sm md:text-base inter">
                Setting kids up for success
              </p>
              <div className="inter font-[700] max-sm:col-span-2 max-sm:flex-col max-sm:flex max-sm:justify-center max-sm:items-center">
                <br /><br />
                <div className="flex flex-row flex-wrap gap-4 mt-2">
                  <img src={facebook} onClick={() => window.open('https://www.facebook.com/profile.php?id=61560199855092&sk=followers', '_blank')} alt="tweeter" />
                  <img src={linkedin} onClick={() => window.open('https://www.linkedin.com/in/karmelleorgreenfeld/', '_blank')} alt="linkedin" />
                  <img src={tweeter} onClick={() => window.open('https://x.com/FiscalKids', '_blank')} alt="tweeter" />
                  <img src={instagram} onClick={() => window.open('https://www.instagram.com/fiscalkids/', '_blank')} alt="instagram" />
                </div>
              </div>
            </div>
            {/* featured links */}
            <div className="flex flex-col gap-3 urbanist">
              <h1 className="text-xs font-extrabold uppercase sm:text-sm md:text-base">
                FEATURED LINKS
              </h1>
              <ul className="flex flex-col gap-3">
                <Link to={"/"}>
                  <li className="text-xs font-medium sm:text-sm md:text-base inter">
                    Home
                  </li>
                </Link>
                <Link to={"/aboutus"}>
                  <li className="text-xs font-medium sm:text-sm md:text-base inter">
                    About us
                  </li>
                </Link>{" "}
                <Link to={"/testimonials"}>
                  <li className="text-xs font-medium sm:text-sm md:text-base inter">
                    Testimonials
                  </li>
                </Link>
                <Link to={"/bookworkshop"}>
                  <li className="text-xs font-medium sm:text-sm md:text-base inter">
                    Workshops
                  </li>
                </Link>
              </ul>
            </div>

            {/* enroll */}
            <div className="flex flex-col gap-3 urbanist">
              <p className="text-xs font-extrabold uppercase sm:text-sm md:text-base ">
                Enroll
              </p>
              {pathname === "/" ? (
                <AnchorLink
                  href="#courses"
                  className="text-xs font-medium sm:text-sm md:text-base inter"
                >
                  Courses
                </AnchorLink>
              ) : (
                <Link to="/" className="text-xs font-medium sm:text-sm md:text-base inter">Courses</Link>
              )}
              {/* <Link className="text-xs font-medium sm:text-sm md:text-base inter">
                Workshop
              </Link> */}
              {pathname === "/bookworkshop" ? (
                <AnchorLink
                  href="#grades"
                  className="text-xs font-medium sm:text-sm md:text-base inter"
                >
                  Grades
                </AnchorLink>
              ) : (
                <Link to="/bookworkshop" className="text-xs font-medium sm:text-sm md:text-base inter">Grades</Link>
              )}
            </div>

            {/* connect */}
            <div className="flex flex-col gap-3 urbanist">
              <p className="text-xs font-extrabold uppercase sm:text-sm md:text-base ">
                Let's Connect
              </p>
              <a
                href="mailto:book@fiscal-kids.com"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center gap-1 text-xs font-medium sm:text-sm md:text-base inter"
              >
                <MdOutlineMail />
                book@fiscal-kids.com
              </a>
              <a
                href="tel:+16043554949"
                className="flex items-center gap-1 text-xs font-medium sm:text-sm md:text-base inter"
              >
                <MdLocalPhone />
                +16043554949
              </a>
            </div>
            {/* <div className="inter font-[700]">
              <h1
                style={{
                  fontWeight: "800",
                  fontSize: "18px",
                  textTransform: "uppercase",
                }}
              >
                Company
              </h1>
              <ul className="mt-2 font-[500]">
                <li className="mb-2">Privacy Policy</li>
                <Link to={"/termsofuse"}>
                  <li className="mb-2">Terms and Conditions</li>
                </Link>
                <Link to={'/contactus'}>
                  <li>Contact Us</li>
                </Link>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-screen bg-barBlue text-grey6">
        <div className="flex justify-center w-10/12 p-5">
          <h1>© 2024 Fiscal Kids. All rights reserved.</h1>
        </div>
      </div>
    </div>
  );
};
export default Footer;
