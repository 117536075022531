import React, { useState } from "react";
import Navbar from "../Component/Navbar/Navbar";
import Footer from "../Component/Footer/Footer";
import Star from "../Assets/Icons/star.svg";
import CourseCard from "../Component/CourseCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourses, getAllCategories } from "../Redux/ApiCalls";
import { Link } from "react-router-dom";
// import Swal from "sweetalert2";
// Icons here

import { BiFilter } from "react-icons/bi";

import { AiFillInfoCircle } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import {
  BsArrowUpRight,
  BsFillArrowUpRightSquareFill,
} from "react-icons/bs";


const Courses = () => {
  const dispatch = useDispatch();
  const {  isFetching, categories, courses } = useSelector((state) => state.courses);
  const [isCoursesFetched, setIsCoursesFetched] = useState(false);
  const [selectedCate, setSelectedCate] = useState(1);
  const [accordianState, setAccordianState] = useState({
    rating: false,
    videoDuration: false,
  });

  const handleSubmit = async () => {
    // e.preventDefault();
    await getAllCategories(dispatch);
    await getAllCourses(dispatch);
    setIsCoursesFetched(true);
  };
  React.useEffect(() => {
    // console.log(courses)
    window.scrollTo(0, 0);
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCoursesFetched]);


  return (
    <>
      <div className="h-screen w-full">
        <Navbar />
        <div className="max-sm:m-8 m-20 max-md:m-10 max-lg:m-16">
          <h2 className="flex justify-start items-start roboto text-btnBlue font-semibold md:text-2xl lg:text-3xl my-2">
            Most <b className="text-disabledBlue">&nbsp;Popular Category's</b>
          </h2>
          <p className="text-grey14 text-sm">
            Various versions have evolved over the years, sometimes by accident,
          </p>

          <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4 mt-10">
            {categories?.map((course) => (
              <div
                key={course?.id}
                className={`shadow-card my-3 mr-2 p-3 cursor-pointer ${selectedCate === course?.id
                  ? "border-ringYellow border rounded-md transition-all duration-300 ease-in-out"
                  : ""
                  }`}
                onClick={() => {
                  setSelectedCate(course?.id);
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <p
                      className={`mr-4 text-3xl ${selectedCate === course?.id
                        ? "text-black2"
                        : "text-grey15"
                        }`}
                    >
                      {course?.icon}
                    </p>
                    <p className="text-xl">{course?.name}</p>
                  </div>

                  <p className="text-3xl text-ringYellow">
                    {selectedCate === course?.id ? (
                      <BsFillArrowUpRightSquareFill className="" />
                    ) : (
                      <BsArrowUpRight />
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div>
            <h1 className="flex justify-start items-start roboto text-btnBlue font-semibold text-2xl lg:text-5xl mt-20 md:mb-10 mb-4">
              Most <b className="text-disabledBlue">&nbsp;Popular Course</b>
            </h1>

            <p className="lg:text-xl">All IT & Software courses</p>

            <div className="flex items-center border border-grey16 p-4 mt-4">
              <p className="text-2xl mr-2">
                <AiFillInfoCircle />
              </p>
              <p className="md:text-sm text-xs">
                Not sure? All courses have a 30-day money-back guarantee
              </p>
            </div>

            <div className="flex items-center justify-between mt-4">
              <div className="flex items-center text-xs lg:text-lg">
                <p className="flex items-center border border-black mr-2 p-2">
                  <BiFilter /> Filter
                </p>
                <p className="flex items-center border border-black p-2">
                  Sort by <IoIosArrowDown />
                </p>
              </div>

              <div>
                <p className="roboto text-grey14 lg:text-sm text-xs">
                  {courses?.length} results
                </p>
              </div>
            </div>

            <div className="grid lg:grid-cols-4 md:grid-cols-3 gap-4 mt-4">
              <div className="hidden md:block">
                {/* sidebar */}

                <div className="border-t border-grey16 cursor-pointer transition-all duration-300 ease-in-out">
                  <p
                    onClick={() => {
                      setAccordianState({ rating: !accordianState?.rating });
                    }}
                    className="flex items-center justify-between my-2"
                  >
                    Ratings <IoIosArrowDown />
                  </p>
                  {accordianState?.rating && (
                    <>
                      <div className="flex items-center my-3 text-xs">
                        <input
                          type="checkbox"
                          name=""
                          value=""
                          className="mr-2"
                        />
                        <p className="flex items-center">
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                        </p>
                        <p className="ml-2">
                          4.5 & up{" "}
                          <span className="text-grey14">(10,000) Result</span>
                        </p>
                      </div>
                      <div className="flex items-center my-3 text-xs">
                        <input
                          type="checkbox"
                          name=""
                          value=""
                          className="mr-2"
                        />
                        <p className="flex items-center">
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                        </p>
                        <p className="ml-2">
                          4.0 & up{" "}
                          <span className="text-grey14">(10,000) Result</span>
                        </p>
                      </div>
                      <div className="flex items-center my-3 text-xs">
                        <input
                          type="checkbox"
                          name=""
                          value=""
                          className="mr-2"
                        />
                        <p className="flex items-center">
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                        </p>
                        <p className="ml-2">
                          3.5 & up{" "}
                          <span className="text-grey14">(10,000) Result</span>
                        </p>
                      </div>
                      <div className="flex items-center my-3 text-xs">
                        <input
                          type="checkbox"
                          name=""
                          value=""
                          className="mr-2"
                        />
                        <p className="flex items-center">
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                          <img src={Star} alt="star rating" className="h-4" />
                        </p>
                        <p className="ml-2">
                          3.0 & up{" "}
                          <span className="text-grey14">(10,000) Result</span>
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <div className="border-t border-grey16 cursor-pointer transition-all duration-300 ease-in-out">
                  <p
                    onClick={() => {
                      setAccordianState({
                        videoDuration: !accordianState?.videoDuration,
                      });
                    }}
                    className="flex items-center justify-between my-2"
                  >
                    Video Duration <IoIosArrowDown />
                  </p>
                  {accordianState?.videoDuration && (
                    <>
                      <div className="flex items-center my-3 text-xs">
                        <input
                          type="checkbox"
                          name=""
                          value=""
                          className="mr-2"
                        />
                        <p className="">
                          0-1 Hour
                          <span className="text-grey14 ml-1">(9,808)</span>
                        </p>
                      </div>
                      <div className="flex items-center my-3 text-xs">
                        <input
                          type="checkbox"
                          name=""
                          value=""
                          className="mr-2"
                        />
                        <p className="">
                          1-3 Hours
                          <span className="text-grey14 ml-1">(8,588)</span>
                        </p>
                      </div>
                      <div className="flex items-center my-3 text-xs">
                        <input
                          type="checkbox"
                          name=""
                          value=""
                          className="mr-2"
                        />
                        <p className="">
                          3-6 Hours
                          <span className="text-grey14 ml-1">(6,321)</span>
                        </p>
                      </div>
                      <div className="flex items-center my-3 text-xs">
                        <input
                          type="checkbox"
                          name=""
                          value=""
                          className="mr-2"
                        />
                        <p className="">
                          6-17 Hours
                          <span className="text-grey14 ml-1">(6,138)</span>
                        </p>
                      </div>
                      <div className="flex items-center my-3 text-xs">
                        <input
                          type="checkbox"
                          name=""
                          value=""
                          className="mr-2"
                        />
                        <p className="">
                          17+ Hours
                          <span className="text-grey14 ml-1">(1,700)</span>
                        </p>
                      </div>
                    </>
                  )}
                </div>


                <div className="border-t border-grey16 cursor-pointer">
                  <p className="flex items-center justify-between my-2">
                    Category <IoIosArrowDown />
                  </p>
                </div>
                <div className="border-t border-grey16 cursor-pointer">
                  <p className="flex items-center justify-between my-2">
                    Class <IoIosArrowDown />
                  </p>
                </div>

                <div className="border-t border-grey16 cursor-pointer">
                  <p className="flex items-center justify-between my-2">
                    Price <IoIosArrowDown />
                  </p>
                </div>
              </div>
              {
                isFetching ?
                  <div className='flex gap-5 w-full py-4 px-12  manrope bg rounded-xl text-btnBlue font-bold text-xl'>
                    <div className='w-8 h-8 bg-transparent rounded-full animate-spin border border-t-8' />
                    <span>
                      Loading...
                    </span>
                  </div> :
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 lg:col-span-3 md:col-span-2 col-span-5 gap-10">
                    {/* course card */}
                    {isFetching ? "" : courses?.map((course, index) => (
                      <Link to={`coursedetail/${course._id}`} key={index} className="flex justify-center">
                        <CourseCard data={course} />
                      </Link>
                    ))}
                  </div>
              }
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Courses;
