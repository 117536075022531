import { createSlice } from "@reduxjs/toolkit";


const staticSlice = createSlice({
    name: "static",
    initialState: {
        images: [],
        isFetching: false,
        error: false,
        errMsg: "",
    },
    reducers: {
        getimagesStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        getimagesSuccess: (state, action) => {
            state.errMsg = "";
            state.error = false;
            state.isFetching = false;
            state.images = action.payload.images;
        },
        getimagesFailure: (state, action) => {
            state.errMsg = action.payload;
            state.isFetching = false;
            state.error = true;
        },

    },
});

export const {
    getimagesStart,
    getimagesSuccess,
    getimagesFailure,
} = staticSlice.actions;
export default staticSlice.reducer;
