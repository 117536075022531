import React, { startTransition } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import Slider from "react-slick";
import Swal from "sweetalert2";
import Navbar from "../Component/Navbar/Navbar";
import { getTestimonials } from "../Redux/ApiCalls";

// import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Footer from "../Component/Footer/Footer";
import ParentsReviewCard from "../Component/ParentsReviewCard";

const Testimonials = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const currentUrl = location.pathname;
  const [testimonialFetched, setTestimonialFetched] = React.useState(false);
  const [
    // eslint-disable-next-line no-unused-vars
    isLoading,
    setIsLoading,
  ] = React.useState(true);
  const handleSubmit = async () => {
    startTransition(async () => {
      await getTestimonials(dispatch);
      // await getAllCourses(dispatch);
      setTestimonialFetched(true);
      setIsLoading(false);
    });
  };

  const modal = (data) => {
    Swal.fire({
      title: `<strong>${data.title}</strong>`,
      html: `${data.description}</br> </br> </br> ${data.user.name}`,
      showCloseButton: true,
    });
  };
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const { testimonials } = useSelector((state) => state.testimonials);

  React.useEffect(() => {
    const state = location.state && location.state.dataIndex;
    if (state) {
      window.scrollTo(0, 1000);
    } else {
      window.scrollTo(0, 0);
    }
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonialFetched]);
  const itemGroups = [];
  for (let i = 0; i < testimonials.length; i += 2) {
    itemGroups.push(testimonials.slice(i, i + 2));
  }
  //
  return (
    <div className="w-screen h-screen">
      <Navbar />

      <div className="w-11/12 urbanist test-banner">
        <p
          className=" w-6/12 max-sm:w-11/12"
          style={{
            fontSize: "30px",
          }}
        >
          “Financial literacy is an essential part of knowing how to avoid
          financial mistakes and devising plans for a secure and strong
          financial future.”
        </p>
      </div>
      {/* <div className="flex justify-center h-40 items-center flex-col mb-5">
        <h1 className="text-4xl max-sm:text-3xl urbanis font-[700] text-btnBlue mb-2">
          What people like about us
        </h1>

        <p className="w-10/12 mali text-center font-bold text-grey9 max-sm:text-base max-sm:text-center whitespace-pre-wrap">
          Hear from the teachers, parents, and the kids who have
          experienced our program.
        </p>
      </div> */}
      <div className="test-con urbanist desktop">
        {testimonials &&
          testimonials.map((item, index) => (
            <div className="testimonials-cont cardBgGR" key={index}>
              <div className="img">
                <img src={item.user.image} alt={item.title} />
              </div>
              <div className="content">
                <h1> {item.title}</h1>
                <h6>{item.user.name}</h6>
                <p>{item.description}</p>
                {item.description.length > 300 ? (
                  <b
                    onClick={() => modal(item)}
                    style={{
                      cursor: "pointer",
                      color: "orange",
                      position: "relative",
                      bottom: "20px",
                    }}
                  >
                    Read more...
                  </b>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="test-con urbanist mobile">
        <div className="grid   testi-conta">
          {testimonials.length === 0 ? (
            <div className="w-8 h-8 bg-transparent rounded-full  border border-t-8" />
          ) : (
            <Slider {...settings}>
              {testimonials.map((item, index) => (
                <ParentsReviewCard key={index} data={item} />
              ))}
            </Slider>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Testimonials;
