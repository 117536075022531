import React, { startTransition, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
// import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardService from "../Component/CardService";
import Aboutus from "../Pages/ContactUs";
import { getTestimonials } from "../Redux/ApiCalls";
// import UserContext from '../utils/UserContext';
import { CiLocationArrow1 } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";
import { PiBankLight, PiHandshake } from "react-icons/pi";
import ClassButton from "../Component/ClassButton";
import backpack from "../Assets/Icons/Backpack.svg";
import booksLkg from "../Assets/Icons/books-lkg.svg";
import bookself from "../Assets/Icons/Bookshelf.svg";
import planet from "../Assets/Icons/Planet.svg";
import books from "../Assets/Icons/books.svg";
// import { IoCardOutline } from "react-icons/io5";
// import { HiOutlineReceiptTax } from "react-icons/hi";
// import { TbPigMoney } from "react-icons/tb";
// import person from '../Assets/Images/money-wallet.png';
// import person_3 from '../Assets/Images/ios-calculator.png';
// import person_4 from '../Assets/Images/medical-book.png';
// import Karmell from '../Assets/Images/karmell.jpeg';
// import books from '../Assets/Icons/books.svg';
// import booksLkg from '../Assets/Icons/books-lkg.svg';
// import backpack from '../Assets/Icons/Backpack.svg';
// import planet from '../Assets/Icons/Planet.svg';
// import bookself from '../Assets/Icons/Bookshelf.svg';
import { GiPayMoney } from "react-icons/gi";
import { LuUsers } from "react-icons/lu";
import { TbCoins, TbTableShortcut } from "react-icons/tb";
import Slider from "react-slick";
import Fees from "../Component/Fees";
import Tag from "../Component/Tag";
// import kids from '../Assets/Images/kids.png';
import { GiMoneyStack } from "react-icons/gi";
// import chevronR from '../Assets/Images/chevron-right.svg';
// import chevronL from '../Assets/Images/chevron-right-1.svg';
import { MdOutlineBusiness } from "react-icons/md";
import service_img4 from "../Assets/Images/bitcoin.png";
import service_img3 from "../Assets/Images/k.png";
import service_img2 from "../Assets/Images/money.png";
import service_img1 from "../Assets/Images/women.png";
import Navbar from "../Component/Navbar/Navbar";
// import BlogPostCard from '../Component/BlogPostCard';
import { useLocation } from "react-router-dom";
import Footer from "../Component/Footer/Footer";
import ParentsReviewCard from "../Component/ParentsReviewCard";
// const Counter = React.lazy(() => import('../Component/Counter'));
// import Counter from ;

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [buttons, setButtons] = React.useState([]);
  const { gradesData } = useSelector((state) => state.grades);
  const [isEng, setisEng] = useState(true);
  const currentUrl = location.pathname;
  const scrollToDiv = () => {
    // Scroll to the top of the target div
    if (currentUrl !== "/") {
      console.log("f");
    } else {
      if (targetDivRef.current) {
        targetDivRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  function getRandomIndex(arr) {
    return Math.floor(Math.random() * arr.length);
  }
  const imgSrcOptions = [backpack, planet, bookself, books, booksLkg];
  const btnColors = [
    "iconYellow",
    "iconred",
    "iconPurple",
    "btnBlue",
    "iconBlue",
  ];

  const createButtons = (data) => {
    return new Promise((resolve, reject) => {
      try {
        const solvedArray = data.map((grade, index) => ({
          imgSrc: imgSrcOptions[getRandomIndex(imgSrcOptions)],
          text: grade,
          className: btnColors[getRandomIndex(btnColors)],
          index: index,
        }));
        resolve(solvedArray);
      } catch (error) {
        reject(error);
      }
    });
  };

  function extractTitles(programs) {
    return new Promise((resolve, reject) => {
      try {
        if (!Array.isArray(programs)) {
          throw new Error("Input must be an array");
        }

        const titles = programs.map((program) => program.title);
        resolve(titles);
      } catch (error) {
        reject(error);
      }
    });
  }

  const handleClassButtons = (butt) => {
    setButtons(butt);
  };

  React.useEffect(() => {
    extractTitles(gradesData).then((titles) => {
      createButtons(titles).then((solvedArray) => {
        handleClassButtons(solvedArray);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradesData]);

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  // };

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  const targetDivRef = React.useRef(null);
  // const divRef = React.useRef();
  // const tagsArray = [
  //     { icon: <PiHandshake className='text-[#35B0FC] text-5xl' />, label: 'Be Better Informed to Choose Your Path in Adulthood' },
  //     { icon: <PiHandshake className='text-[#35B0FC] text-5xl' />, label: 'Consumer Awareness' },
  //     { icon: <PiBankLight className='text-[#35B0FC] text-5xl' />, label: 'How to Use Banking Services' },
  //     { icon: <IoIosSearch className='text-[#35B0FC] text-5xl' />, label: 'When Do You Need Insurance & What Options Are Available' },
  //     { icon: <LuUsers className='text-[#35B0FC] text-5xl' />, label: 'Mastering Entrepreneurship: Financial and Risk Management' },
  //     { icon: <TbCoins className='text-[#35B0FC] text-5xl' />, label: 'Learn How to Set Financial Goals and Budget for Them' },
  //     { icon: <GiPayMoney className='text-[#35B0FC] text-5xl' />, label: 'The Importance of Investing - Active & Passive' },
  //     { icon: <IoCardOutline className='text-[#35B0FC] text-5xl' />, label: 'Introduction to the Concept of Credit & Debt and How to Manage It' },
  //     { icon: <TbPigMoney className='text-[#35B0FC] text-5xl' />, label: 'Understand the Concept of Earning, Saving, Spending & Donating Your Money' },
  //     { icon: <HiOutlineReceiptTax className='text-[#35B0FC] text-5xl' />, label: 'Gaining a Foundational Understanding of Taxes' },
  // ];
  const [slicer, setSlicer] = React.useState(4);
  const tagsArray = [
    {
      icon: <TbTableShortcut className="text-[#B83B5E] text-4xl" />,
      label: "Money Management",
    },
    {
      icon: <PiHandshake className="text-[#B83B5E] text-4xl" />,
      label: "Needs vs Wants",
    },
    {
      icon: <PiBankLight className="text-[#B83B5E] text-4xl" />,
      label: "Career Options",
    },
    {
      icon: <IoIosSearch className="text-[#B83B5E] text-4xl" />,
      label: "Setting Goals",
    },
    {
      icon: <LuUsers className="text-[#B83B5E] text-4xl" />,
      label: "Budgeting Mastery",
    },
    {
      icon: <TbCoins className="text-[#B83B5E] text-4xl" />,
      label: "Investing & Saving",
    },
    {
      icon: <GiPayMoney className="text-[#B83B5E] text-4xl" />,
      label: "Taxes & How They Affect Your Income",
    },
    {
      icon: <MdOutlineBusiness className="text-[#B83B5E] text-4xl" />,
      label: "Entrepreneurship",
    },
    {
      icon: <GiMoneyStack className="text-[#B83B5E] text-4xl" />,
      label: "Active vs Passive Income",
    },
  ];
  const [testimonialFetched, setTestimonialFetched] = React.useState(false);
  const [
    // eslint-disable-next-line no-unused-vars
    isLoading,
    setIsLoading,
  ] = React.useState(true);

  const { testimonials } = useSelector((state) => state.testimonials);

  // const { errMsg, isFetching, error, courses } = useSelector((state) => state.courses);
  const handleSubmit = async () => {
    startTransition(async () => {
      await getTestimonials(dispatch);
      // await getAllCourses(dispatch);
      setTestimonialFetched(true);
      setIsLoading(false);
    });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonialFetched]);

  const itemGroups = [];
  for (let i = 0; i < testimonials?.length; i += 2) {
    itemGroups.push(testimonials.slice(i, i + 2));
  }

  return (
    <div className="w-screen h-screen">
      <Navbar to={scrollToDiv} scrollToDiv={scrollToDiv} />
      <div className="banner">
        {/* <img
      <div className="banner">
        {/* <div>
          <img
            src={rocket}
            alt="rocket"
            className="animate rocket_img"
            data-aos="fade-down"
          /> */}
        {/* <img
            src={maskGroup}
            alt="kids"
            className="maskGroup banner-img-kids"
          /> */}
        {/* <img
            src={trophy}
            alt="trophy"
            className="trophy desk-view"
            data-aos="fade-down"
          /> */}
        {/* <div className="absolute flex items-center justify-center -translate-x-1/2 translate-y-1/2 top-1/2 sm:top-[70%] left-1/2">
          <button
            onClick={() => navigate("/bookworkshop")}
            className="p-3 text-sm text-white rounded-md sm:text-sm sm:p-4 md:text-md bg-btnBlue sm:rounded-xl manrope"
          >
            Book a workshop
          </button>
        </div> */}
      </div>
      {/* ___________________________Book a workshop button__________________________ */}
      <div>
        <button
          onClick={() => navigate("/bookworkshop")}
          className="flex items-center justify-center h-12 p-4 text-white bg-btnBlue rounded-xl max-sm:h-10 w-50 max-sm:w-40 manrope text-md max-sm:text-sm hover:scale-90 book-workshop-button"
        >
          Book a workshop
        </button>
      </div>
      {/* ___________________________Absolue design imgs__________________________ */}
      {/* <img src={person_3} alt="flying human" className='per-1 desk-view hover:animate-bounce' data-aos="fade-down" data-aos-duration="2500" /> */}
      {/* <img src={person_4} alt="flying human" className='per-2 desk-view hover:animate-bounce' data-aos="fade-down" data-aos-duration="2500" /> */}

      {/* <img
        src={person_1}
        alt="flying human"
        className="per-4 desk-view hover:animate-bounce"
        data-aos="fade-down"
        data-aos-duration="2500"
      />
      <img
        src={person_2}
        alt="flying human"
        className="per-5 desk-view hover:animate-bounce"
        data-aos="fade-down"
        data-aos-duration="2500"
      /> */}
      {/* ________________________________________________________________ */}

      {/* ___________________________kids__________________________ */}

      {/* <div className='flex flex-row items-end justify-center w-full mt-20'>
                <div className='h-[88px] bg-rectYellow w-full' />
                <img src={kids} alt="kids" className='object-cover kids w-fit' />
                <div className='h-[88px] bg-rectYellow w-full' />
            </div> */}

      {/* ________________________________________________________________ */}

      {/* <<<<<<<<<<<<<Counter is here!>>>>>>>>>>>>>  */}

      {/* <div className='flex items-center justify-center w-screen max-sm:hidden h-max my-28' data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom">
                <div className=' absolute bg-btnBlue h-[230px] w-[693px] z-0 right-[22vw] top-10 rounded-tl-[86px] rounded-tr-[32px] rounded-br-[56px]' />
                {
                    !isLoading && <Counter />
                }
            </div> */}

      <div id="courses" className="flex flex-col justify-center items-center gap-8 max-sm:gap-4 mt-[1vh] max-sm:mt-[0vh] max-sm:mb-[3vh] max-sm:py-[10px] py-20">
        <h1 className="text-4xl mx-5 max-sm:text-3xl urbanis font-[700] text-btnBlue">
          Courses
        </h1>
        <Fees />
      </div>

      <div className="flex flex-col justify-center items-center font-[500] mt-20 mb-8">
        <h1 className="text-4xl max-sm:text-3xl urbanis font-[700] text-btnBlue mb-10">
          Setting kids up for success
        </h1>
        <div className="flex flex-wrap items-center justify-center w-10/12 gap-5 align-middle lg:hidden md:hidden xl:hidden max-sm:w-9/12">
          {tagsArray?.slice(0, slicer).map((tag, index) => (
            <Tag icon={tag.icon} label={tag.label} key={index} index={index} />
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center w-10/12 gap-5 align-middle max-sm:hidden max-sm:w-9/12">
          {tagsArray?.map((tag, index) => (
            <Tag icon={tag.icon} label={tag.label} key={index} index={index} />
          ))}
        </div>
        {/* view more */}
      </div>
      <center>
        <button
          type="button"
          onClick={() =>
            setSlicer(slicer === tagsArray.length ? 4 : tagsArray.length)
          }
          style={{ height: "40px" }}
          className="items-center justify-center hidden p-5 mt-2 mb-10 text-xl text-white learm-more group max-sm:flex bg-btnBlue rounded-xl max-sm:h-12 w-fit max-sm:w-42 urbanist max-sm:text-sm hover:scale-97 "
        >
          <strong style={{ fontSize: "20px" }}>
            {slicer === tagsArray.length ? "View Less" : "View More"}
          </strong>
        </button>
      </center>
      {/* ---------------------------Video section----------------------------- */}
      <div className="flex flex-col items-center justify-center pb-20 workshop-gradient-back">
        <div className="flex flex-col justify-center items-center my-10 font-[500] gap-5">
          <h1 className="text-4xl text-center max-sm:text-4xl max-sm:ml-5 urbanis font-[700] text-btnBlue">
            Fiscal Kids changes lives forever
          </h1>
          <div className="flex flex-row items-center justify-start gap-3">
            <button
              onClick={() => {
                setisEng(!isEng);
              }}
              className={`${isEng ? "text-btnBlue border-b-2 border-btnBlue" : "text-grey4"
                }`}
            >
              English
            </button>
            <button
              onClick={() => {
                setisEng(!isEng);
              }}
              className={`${!isEng ? "text-btnBlue border-b-2 border-btnBlue" : "text-grey4"
                }`}
            >
              Spanish
            </button>
          </div>
        </div>
        <div className="flex items-center justify-center w-screen">
          {isEng && (
            <iframe
              className={
                isEng
                  ? "rounded-xl aspect-video w-[70vw] max-sm:w-11/12"
                  : "hidden"
              }
              src="https://www.youtube.com/embed/Z1485hux2Js?si=EoIg5qai0Dt2Z4_J"
              title="Karmel Leor Greenfeld"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
          {!isEng && (
            <iframe
              className={
                !isEng
                  ? "rounded-xl aspect-video w-[70vw] max-sm:w-11/12"
                  : "hidden"
              }
              src="https://www.youtube.com/embed/SNhc6eCtKZM?si=edEZl4iJuG9RthbP"
              title="Karmel Leor Greenfeld"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </div>
      {/* -------------------------------------------------------- */}
      {/* <div className="flex flex-col items-center justify-center gap-6 py-10 max-sm:py-5 about-us" style={{ color: "white", paddingTop: "10px" }}>
        <h1 className="flex justify-start mt-10 mb-2 text-4xl font-extrabold items-star max-sm:text-4xl max-sm:mt-2 max-sm:mb-0 max-sm:my-5">
          About Us
        </h1>
        <p className="w-10/12 max-sm:w-11/12" style={{ textAlign: "center" }}>
          <strong
            className="urbanist "
            style={{ fontSize: "20px", textAlign: "center" }}
          >
            Founded by <em>Karmel Leor Greenfeld</em> , Fiscal Kids empowers children with
            lifelong financial skills. Our dynamic programs cultivate early
            money habits, budgeting, saving, and wise spending. Beyond financial
            stability, we emphasize emotional intelligence, instilling purpose
            and the satisfaction of achieving financial goals, and steering
            clear of debt traps. Equipping kids for a future of informed and
            responsible decision-making.
          </strong>
        </p>
        <button
          type="button"
          onClick={() => navigate("/aboutus")}
          className="learm-more group p-3 flex justify-center items-center border border-white bg-[#B83B5E33] text-white rounded-xl h-16 max-sm:h-12 w-fit max-sm:w-42 urbanist text-xl max-sm:text-sm hover:scale-97"
        >
          <strong>Learn more about Fiscal Kids</strong>
          <CiLocationArrow1
            className="ml-2 text-[40px] max-sm:text-[20px] rotate-0 "
            style={{ transform: "rotateZ(45deg)" }}
          />
        </button>
      </div> */}
      <div className="flex flex-col justify-center items-center h-fit max-sm:h-max py-10 mb-[100px] px-4">
        <div className="flex flex-col justify-center items-center my-10 font-[500] gap-5">
          <h1 className="text-4xl text-center max-sm:text-4xl max-sm:ml-5 urbanis font-[700] text-btnBlue">
            Why our workshops are important
          </h1>
          <p
            className="mb-3 font-bold text-center whitespace-pre-wrap mali text-grey9 max-sm:text-base max-sm:text-center"
            style={{ fontSize: "20px" }}
          >
            Early exposure to financial concepts nurtures lifelong confidence
            and paves the way for lasting achievements.
          </p>
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-y-8 gap-x-6 lg:w-10/12 card-service-cont">
          <CardService
            img={service_img1}
            heading={
              "Empower young people's financial futures with confidence!"
            }
            text={
              " Incorporating financial literacy education into a child's upbringing sets the stage for a lifetime of financial well-being, responsible decision-making, and the ability to set and achieve their financial goals."
            }
          />
          <CardService
            img={service_img3}
            heading={"Preventing regrets, cultivating wisdom"}
            text={
              "It's a common sentiment among adults to wish they had learned about investing and financial management at a younger age. Fiscal Kids aims to break this cycle by instilling essential money skills early on, preventing future regrets, and enabling our students to make the most of their financial opportunities."
            }
          />
          <CardService
            img={service_img2}
            heading={"Modern finances made fun"}
            text={
              " In today's rapidly changing world, financial literacy is more crucial than ever. Financially literate kids have a head start in navigating the complexities of the modern world."
            }
          />

          <CardService
            img={service_img4}
            heading={"Join the fiscal kids movement"}
            text={
              "Embark on a journey with Fiscal Kids, where learning about money is an adventure. Our workshops and classes go beyond textbooks, offering a dynamic and interactive approach to financial education."
            }
          />
        </div>
      </div>

      {/* -------------------Our popular Courses here!!------------------- */}
      {/* <div className='flex flex-col items-center justify-center'>
                <div className='flex flex-col justify-center items-center my-20 font-[500] gap-5'>
                    <h3 className='heading-3 inter text-iconBlue'>
                        Learning the section
                    </h3>
                    <h1 className='text-6xl max-sm:text-xl mali font-[700] text-subHeading2'>
                        Our Most Popular Courses
                    </h1>
                    <p className='mali font-[600] text-grey2 max-sm:text-center '>
                        Let's join our famous class, the knowledge provided will definitely be useful for you.
                    </p>
                </div>
                <div className='flex items-center justify-center w-screen'>
                    <div className='grid grid-cols-3 grid-rows-2 my-5 max-sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7'>
                        {courses.slice(0, 6).map((course, index) => (
                            <Link to={`/courses/coursedetail/${course._id}`} key={index}>
                                <BlogPostCard data={course} key={index} />
                            </Link>
                        ))}
                    </div>
                </div>
                <button onClick={() => navigate('/courses')} className='inter rounded-[8px] text-[14px] bg-subHeading2 py-[10px] px-[16px] border border-btnGreyBorder text-white hover:bg-btnBlue'>
                    Explore All Courses
                </button>
            </div> */}
      {/* -------------------Our popular Courses ends here!!------------------- */}
      <div className="flex flex-col items-center justify-center p-8 mb-10 h-fit max-sm:h-max workshop-gradient">
        <div className="flex flex-col justify-center items-center my-10 font-[500] gap-5">
          <h1 className="text-4xl urbanis max-sm:text-4xl mx-5 font-[700] text-btnBlue text-center">
            What people like about us
          </h1>
          <p className="mb-3 text-2xl font-bold text-center whitespace-pre-wrap mali text-grey9 max-sm:text-base max-sm:text-center">
            Hear from the teachers, parents, and the kids who have experienced
            our program.
          </p>
        </div>
        <div className="flex items-center justify-center w-screen">
          <div className="grid testi-conta">
            {testimonials?.length === 0 ? (
              <div className="w-8 h-8 bg-transparent border border-t-8 rounded-full" />
            ) : (
              <Slider {...settings}>
                {testimonials.map((item, index) => (
                  <ParentsReviewCard key={index} data={item} />
                ))}
              </Slider>
            )}
            <button
              ref={targetDivRef}
              type="button"
              onClick={() =>
                navigate("/testimonials", { state: { dataIndex: true } })
              }
              style={{ height: "50px", margin: "auto" }}
              className="flex items-center justify-center p-5 text-xl text-white learm-more group bg-btnBlue rounded-xl max-sm:h-12 w-fit max-sm:w-42 urbanist max-sm:text-sm hover:scale-97 "
            >
              <strong style={{ fontSize: "20px" }}>View more</strong>
              <CiLocationArrow1
                className="ml-2 text-[25px] max-sm:text-[20px] rotate-0 "
                style={{ transform: "rotateZ(45deg)" }}
              />
            </button>
          </div>
        </div>
      </div>

      <Aboutus />
      <Footer />
    </div>
  );
};

export default Home;
