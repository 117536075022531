import React from "react";
import arrow from '../Assets/Icons/arrow.svg';
const CourseCard = ({ data }) => {
  // console.log("Course data", data);
  return (
    <>
      <div data-aos="flip-left" className='flex flex-col bg-[#d6e6ff] hover:shadow-cardActive shadow-card w-full gap-3 rounded-xl  h-[550px]' style={{ border: '1px solid #d6e6ff' }}>
        <img src={data.poster[0]} alt="science for kids" className='w-full h-[200px] rounded-t-xl' />
        <div className='flex flex-col h-full gap-2 px-6 w-8/10'>
          <h4 className='inter text-[14px] text-disabledBlue'>
            {data.category}
          </h4>
          <div className='font-[700] inter heading-3 flex justify-between'>
            <h2 className='overflow-hidden whitespace-nowrap overflow-ellipsis'>{data.title}</h2>
            <img src={arrow} alt="science for kids" />
          </div>

          <div className="h-[150px] overflow-hidden text-grey4 inter">
            <p className="whitespace-normal">
              {data.description}
            </p>
          </div>
          <div className='flex justify-start items-center gap-[10px]'>
            <p className='flex text-disabledBlue'>
              Class: {data.class_type.map((c,i)=>{return <p key={i} className="mr-2 text-backOrange">{c}</p>})}
            </p>
          </div>
          <div className='flex justify-between'>
            <div className='flex gap-2 inter text-[14px]  w-max'>
              <img src={data.createdBy.avatar} alt="avatar" className='w-[50px] rounded-full' />
              <div>
                <h2>
                  {data.createdBy.name}
                </h2>
                <h3 className='text-grey4'>
                  {data.enrolledStudent.length} Enrolled
                </h3>
              </div>
            </div>
            <div>
              <h1 className='text-[26px] font-[700] inter text-subHeading2'>
                ${data.price}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseCard;
