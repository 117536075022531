import { createSlice } from "@reduxjs/toolkit";

const resetPasswordSlice = createSlice({
    name: "resetPassword",
    initialState: {
        isFetching: false,
        error: false,
        errMsg: "",
        msg: ''
    },
    reducers: {
        resetStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        resetCodeSendSuccess: (state, action) => {
            const { message } = action.payload;
            state.errMsg = '';
            state.isFetching = false;
            state.error = false;
            state.msg = message;
        },
        resetCodeSendFailure: (state, action) => {
            state.errMsg = action.payload;
            state.isFetching = false;
            state.error = true;
        },
        validateCodeStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        validateCodeSuccess: (state, action) => {
            const { message } = action.payload;
            state.msg = message;
            state.isFetching = false;
            state.error = false;
        },
        validateCodeFailure: (state, action) => {
            state.errMsg = action.payload;
            state.isFetching = false;
            state.error = true;
        },
        setPasswordStart: (state, action) => {
            state.isFetching = true;
            state.error = false;
        },
        setPasswordSuccess: (state, action) => {
            const { message } = action.payload;
            state.msg = message;
            state.isFetching = false;
            state.error = false;
        },
        setPasswordFailure: (state, action) => {
            state.errMsg = action.payload;
            state.isFetching = false;
            state.error = true;
        },
    }
});

export const {
    resetStart,
    resetCodeSendSuccess,
    resetCodeSendFailure,
    validateCodeStart,
    validateCodeSuccess,
    validateCodeFailure,
    setPasswordStart,
    setPasswordSuccess,
    setPasswordFailure,
} = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;