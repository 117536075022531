import { createSlice } from "@reduxjs/toolkit";

const testimonialSlice = createSlice({
  name: "testimonials",
  initialState: {
    testimonials: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    getTestimonialsStart: (state, action) => {
      state.isFetching = true;
      state.error = false;
    },
    getTestimonialsSuccess: (state, action) => {
      state.error = false;
      state.isFetching = false;
      state.testimonials = action.payload.testimonials;
    },
    getTestimonialsFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getTestimonialsStart,
  getTestimonialsSuccess,
  getTestimonialsFailure,
} = testimonialSlice.actions;
export default testimonialSlice.reducer;
