import { createSlice } from "@reduxjs/toolkit";

const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        isFetching: false,
        error: false,
        errMsg: "",
    },
    reducers: {
        sendQueryStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        sendQuerySuccess: (state, action) => {
            // const { message } = action.payload;
            state.isFetching = false;
            state.errMsg = action.payload;
            state.error = false;
        },
        sendQueryFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errMsg = action.payload.message;
        },
        sendEnrollStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        sendEnrollSuccess: (state) => {
            state.isFetching = false;
            state.error = false;
        },
        sendEnrollFailure: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errMsg = action.payload.message;
        }
    }
});
export const {
    sendQueryStart,
    sendQuerySuccess,
    sendQueryFailure,
    sendEnrollStart,
    sendEnrollSuccess,
    sendEnrollFailure
} = contactSlice.actions;
export default contactSlice.reducer;