export default function OneOnOne() {
  //curriculum points
  const CURRICULUM = [
    "Understanding your core values",
    "The importance of setting goals",
    "Vision boarding",
    "Generating income building skills",
    "Importance of a positive attitude",
    "Confidence: fake it till you make it",
    "Business etiquette",
    "Understanding how failure and success work",
    "Career development path",
    "Social media etiquette ",
    "Strategies for investing",
    "Understanding compound interest",
    "Having your money work for you instead of you working for your money",
  ];
  return (
    <div className="flex flex-col gap-6 mx-6 my-6 sm:mx-10 md:mx-14">
      {/* introduction */}
      <div className="">
        {/* heading */}
        <h3 className="text-3xl font-bold text-btnBlue">The Empowered Youth</h3>
        <div className="flex flex-wrap gap-5 mt-4 font-semibold text-grey2">
          This course is perfect for students who are looking to build their
          best future based on who they are and what is important to them. This
          course looks at the fundamentals of identifying one’s own values with
          a 360-degree lens on current day required financial skills and
          opportunities.
        </div>
      </div>
      {/* course curriculum */}
      <div className="">
        {/* heading */}
        <h3 className="text-3xl font-bold text-btnBlue">Sample Curriculum </h3>
        <ul className="flex flex-col gap-2 mt-4 ms-6 text-grey2">
          {CURRICULUM.map((ct, i) => {
            return (
              <li key={i} className="list-disc">
                {ct}
              </li>
            );
          })}
        </ul>
      </div>
      {/* conclusion */}
      <div>
        <h3 className="text-3xl font-bold text-btnBlue">Objective </h3>
        <p className="mt-4 text-grey2">
          The one-on-one coaching program is designed to take a student from
          where they currently are and provide them with the information and
          tools required so they can powerfully create their own lives. It
          provides them with the confidence and skills required to permanently
          remove unhealthy stress-creating thoughts and self-limiting beliefs
          that stand in their way of becoming who they want to be. Students who
          graduate from this program experience a sense of liberation from false
          barriers and feel empowered to dream bigger.
        </p>
      </div>
    </div>
  );
}
